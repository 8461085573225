import LoadingComp from "components/loading/Loading";
import useBookmarkStore from "components/page-bookmark/bookmarkStore";
import Pagina from "interfaces/Pagina";
import PageBookmark from "interfaces/pageBookmark";
import User from "interfaces/user";
import HandleClick from "middleware/handle-click";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Skeleton } from "primereact/skeleton";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageService from "services/page.service";
import TokenService from "services/token.service";
import UserService from "services/user.service";
import ArrayUtils from "utils/ArrayUtils";
import UrlUtils from "utils/UrlUtils";
import ToastUtils from "utils/toastUtils";

export default function PageSelector({
  selecionaPagina,
  showRetornaBtn,
  cid,
  gname,
  listStyle,
  selectorStyle,
  tabIndex,
  selectedPage
}: any): React.ReactElement {
  const navigate = useNavigate();
  const [pagesOptions, setPagesOptions] = useState<any[]>([]);
  const [filteredPagesOptions, setFilteredPagesOptions] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<User>();
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex ?? 0);
  const [filterTxt, setFilterTxt] = useState("");
  const bookmark = useBookmarkStore((state: any) => state.bookmark);
  const [pageLoagind, setPageLoading] = useState(false);
  const sectorTabs = [
    {
      title: "Salvos",
      reportTypeId: 1,
    },
    {
      title: "Market Share",
      reportTypeId: 1000,
    },
    {
      title: "Mercados e Canais",
      reportTypeId: 1271,
    },
    {
      title: "Gestão de Categorias",
      reportTypeId: 1272,
    },
    {
      title: "Comportamento Shopper",
      reportTypeId: 1273,
    },
    {
      title: "Pricing",
      reportTypeId: 1274,
    },
    {
      title: "Estimativa de Mercado",
      reportTypeId: 1397,
    },
    {
      title: "Customizações",
      reportTypeId: 0,
    },
  ];
  const [bookmarks, setBookmarks] = useState<any[]>([]);
  const toastRef = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const confirmRef = useRef<OverlayPanel>(null);
  const [scrolled, setScrolled] = useState(false);
  // const [askPermission, setAscPermission] = useState<number[]>([]);

  const checkTabIndex = (reportTypeIdList: number[]): void => {
    if (bookmark.id) {
      setActiveTabIndex(0);
      return;
    }
    if (tabIndex) {
      setActiveTabIndex(tabIndex);
      return;
    }
    if (reportTypeIdList.includes(sectorTabs[activeTabIndex].reportTypeId)) {
      return;
    }
    if (pagesOptions?.length > 0) {
      if (reportTypeIdList.includes(1000)) {
        setActiveTabIndex(1);
      } else if (reportTypeIdList.includes(1271)) {
        setActiveTabIndex(2);
      } else if (reportTypeIdList.includes(1272)) {
        setActiveTabIndex(3);
      } else if (reportTypeIdList.includes(1273)) {
        setActiveTabIndex(4);
      } else if (reportTypeIdList.includes(1274)) {
        setActiveTabIndex(5);
      } else if (reportTypeIdList.includes(1397)) {
        setActiveTabIndex(6);
      } else {
        setActiveTabIndex(7);
      }
    } else {
      setActiveTabIndex(1);
    }
  };

  useEffect(() => {
    const reportTypeIdList = pagesOptions.map((item: any) => item.reportTypeId);
    checkTabIndex(reportTypeIdList);
  }, [bookmark]);

  const getPages = (): void => {
    setPageLoading(true)
    PageService.getPagesByCatAndGeo(cid, gname)
      .then((response) => {
        if (response.data) {
          const items = response.data;
          setPagesOptions(items);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getBookmarks = async (): Promise<void> => {
    try {
      const response = await PageService.getBookmarks(cid, gname);
      setBookmarks(response.data);
      if (pagesOptions.length === 0) {
        getPages();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const reportTypeIdList = pagesOptions.map((item: any) => item.reportTypeId);
    checkTabIndex(reportTypeIdList);
  }, [pagesOptions]);

  const retornarGeo = (): void => {
    const param = `?cid=${UrlUtils.getUrlParamFromString(
      window.location.search,
      "cid"
    )}&cname=${UrlUtils.getUrlParamFromString(
      window.location.search,
      "cname"
    )}`;
    navigate({
      pathname: "/geografia",
      search: param,
    });
  };

  const skeletonItem = (): React.ReactElement => {
    return (
      <div
        className="item--card size-2x"
        data-cy="page-list-loading"
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          width: "calc(171px * 2)",
          padding: "15px",
          height: "192px",
        }}
      >
        <div
          style={{
            width: "110px",
            height: "160px",
          }}
        >
          <Skeleton height="100%"></Skeleton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "16px",
          }}
        >
          <Skeleton height="20px"></Skeleton>
          <br />
          <span style={{ marginBottom: "2px" }}>
            <Skeleton></Skeleton>
          </span>
          <span style={{ marginBottom: "2px" }}>
            <Skeleton></Skeleton>
          </span>
          <span style={{ marginBottom: "2px" }}>
            <Skeleton></Skeleton>
          </span>
          <span style={{ marginBottom: "2px" }}>
            <Skeleton></Skeleton>
          </span>
          <span style={{ marginBottom: "2px" }}>
            <Skeleton></Skeleton>
          </span>
        </div>
      </div>
    );
  };

  const getSkeleton = (): React.ReactElement => {
    return (
      <>
        <div className="setor">
          <div className="setor--body">
            {skeletonItem()}
            {skeletonItem()}
            {skeletonItem()}
          </div>
        </div>
      </>
    );
  };

  const deleteBookmark = async (bookmarkId: number): Promise<void> => {
    try {
      setLoading(true);
      const response = await PageService.deleteBookmarkById(bookmarkId);
      toastRef.current?.show({
        ...ToastUtils.defaultSuccessOptions,
        ...{ summary: "Excluído com sucesso" },
      });
      setBookmarks(bookmarks.filter((book) => book.id !== bookmarkId));
    } catch (error) {
      toastRef.current?.show({
        ...ToastUtils.defaultErrorOptions,
        ...{ summary: "Houve um erro ao excluir favorito, contate o suporte." },
      });
    } finally {
      setLoading(false);
    }
  };

  const getTabItems = (pageList: Pagina[]): React.ReactElement => {
    if (!pageList) {
      return <></>;
    }
    pageList = pageList.filter((item) => !item.section.includes("antigo"));
    const sections = [...new Set(pageList.map((page) => page.section))];    
    return (
      <div className="items" style={listStyle}>
        {pagesOptions.length  === 0 && pageLoagind ? (
          getSkeleton()
        ) : (
          <div className="setor">
            <div
              className="setor--body"
              style={{
                flexDirection: "column",
              }}
            >
              {sections.length === 0 ? (
                <>
                  <div>
                    {filterTxt !== "" ? (
                      <span>
                        Não existem relatórios com o filtro{" "}
                        <strong>{filterTxt}</strong>
                      </span>
                    ) : (
                      <span>Não existem relatórios nesta aba</span>
                    )}
                  </div>
                </>
              ) : (
                sections.sort(ArrayUtils.sortDefaultAsc).map((section) => {
                  return (
                    <div
                      key={section}
                      className="sector"
                      style={{
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      {section.split(". ")[0] === "1" ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            width: "calc(100% + 10px)",
                            display: "block",
                            padding: "10px 5px",
                            marginLeft: "-5px",
                            position: "sticky",
                            top: "0",
                            backgroundColor: "#fff",
                            zIndex: "99"
                          }}
                        >
                          <span>
                            {section.includes(". ")
                              ? section.split(". ")[1]
                              : section}
                          </span>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {pageList
                          .filter((page) => page.section === section)
                          .sort((a, b) => ArrayUtils.ascSort(a, b, "title"))
                          .map((page, index) => {
                            const id = `G-${page.id}-${page.reportId ?? index}`
                            const pageSelected = parseInt(window.location.pathname.split("/")[4])
                            const reportSelected = parseInt(window.location.pathname.split("/")[2])
                            if(page.id == pageSelected && page.reportId == reportSelected && !scrolled) {
                              document.querySelector(`#${id}`)?.scrollIntoView();
                              const margem = document.getElementsByClassName("items")[0].scrollTop
                              document.getElementsByClassName("items")[0].scrollTop = margem - 30;
                            }
                            let iconSrc = `https://storage.googleapis.com/v360-platform-assets/seletor-paginas-icons/${page.id}.svg`;
                            if (
                              ![1334, 1383, 68, 21, 20].includes(page.id ?? 0) &&
                              page.demo
                            ) {
                              page.disabled = true;
                            }
                            if (
                              [1029, 1151, 1243].includes(page.reportTypeId)
                            ) {
                              iconSrc =
                                "https://storage.googleapis.com/v360-platform-assets/seletor-paginas-icons/internos-varejo.svg";
                            }
                            if (page.reportTypeId == 1034) {
                              iconSrc =
                                "https://storage.googleapis.com/v360-platform-assets/seletor-paginas-icons/internos-dnn.svg";
                            }
                            return (
                              <Fragment key={page.id}>
                                <div
                                  id={id}
                                  data-cy={`card-page-${page.reportId}-${page.id}`}
                                  className="container--card"
                                  style={{
                                    position: "relative",
                                    width: "calc(171px * 2 + 15px)",
                                    padding: 0,
                                    height: "auto",
                                  }}
                                >
                                  <div
                                    className={`item--card${
                                      page.demo ? " demo" : ""
                                    }${page.disabled ? " disabled" : ""}`}
                                    data-cy="relatorio-card"
                                    style={{
                                      justifyContent: "space-between",
                                      padding: 0,
                                      width: "100%",
                                      margin: "0",
                                    }}
                                    onClick={(e) => {
                                      if (page.disabled) {
                                        return;
                                      }
                                      HandleClick.logClickEvents(
                                        `novo_relatorios_abrir`,
                                        () => selecionaPagina(e, page)
                                      );
                                    }}
                                  >
                                    {window.location.pathname.split('/')[4] === `${page.id}` && window.location.pathname.split('/')[2] === `${page.reportId}`
                                      ? <div data-cy="indicador-pagina-selecionada" style={{
                                          position: "absolute", 
                                          bottom: "0", 
                                          width: "355px", 
                                          height: "5px",
                                          backgroundColor: "rgb(0, 118, 179)",
                                          borderBottomLeftRadius: "5px",
                                          borderBottomRightRadius: "5px"
                                        }}></div> 
                                      : <></>}
                                    <div style={{ display: "flex" }}>
                                      <div
                                        className="item--icon"
                                        style={{
                                          backgroundColor: "#F9F9F9",
                                          height: "192px",
                                          width: "110px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderTopLeftRadius: "5px",
                                          borderBottomLeftRadius: "5px",
                                        }}
                                      >
                                        <img
                                          width={70}
                                          height={70}
                                          src={iconSrc}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "https://storage.googleapis.com/v360-platform-assets/seletor-paginas-icons/generico.svg";
                                          }}
                                          loading="lazy"
                                        ></img>
                                      </div>
                                      <div
                                        className="item--content"
                                        style={{
                                          width: "245px",
                                          color: "#565657",
                                          display: "flex",
                                          flexDirection: "column",
                                          padding: "16px 20px",
                                        }}
                                      >
                                        {page.isBeta && !page.demo ? (
                                          <>
                                            <span
                                              style={{
                                                transform: "rotate(45deg)",
                                                zIndex: "2",
                                                color: "black",
                                                fontSize: "0.6rem",
                                                fontWeight: "bold",
                                                marginTop: "9px",
                                                marginLeft: "157px",
                                              }}
                                            >
                                              RELATÓRIO EXPERIMENTAL
                                            </span>
                                            <svg
                                              width="171px"
                                              height="194px"
                                              style={{
                                                zIndex: "1",
                                                marginTop: "-50px",
                                                marginLeft: "55px",
                                                marginBottom: "-182px",
                                              }}
                                            >
                                              <rect
                                                width="140"
                                                height="25"
                                                style={{
                                                  fill: "#f29200",
                                                  transform:
                                                    "translate(89px, -17px) rotate(45deg)",
                                                  filter:
                                                    "drop-shadow(-3px -4px 4px #a3a3a3)",
                                                }}
                                              />
                                            </svg>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {page.demo && !page.disabled ? (
                                          <>
                                            <span
                                              style={{
                                                transform: "rotate(45deg)",
                                                zIndex: "2",
                                                color: "white",
                                                fontSize: "0.6rem",
                                                fontWeight: "bold",
                                                marginTop: "11px",
                                                marginLeft: "157px",
                                              }}
                                            >
                                              AMOSTRA GRÁTIS
                                            </span>
                                            <svg
                                              width="171px"
                                              height="194px"
                                              style={{
                                                zIndex: "1",
                                                marginTop: "-50px",
                                                marginLeft: "55px",
                                                marginBottom: "-182px",
                                              }}
                                            >
                                              <rect
                                                width="140"
                                                height="25"
                                                style={{
                                                  fill: "#13a538",
                                                  transform:
                                                    "translate(89px, -17px) rotate(45deg)",
                                                  filter:
                                                    "drop-shadow(-3px -4px 4px #a3a3a3)",
                                                }}
                                              />
                                            </svg>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <div
                                          className="header"
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          <span
                                            id={`pageTitle-${page.id}`}
                                            data-cy="card-title"
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                              width:
                                                page.bookmarkId &&
                                                tabIndex === undefined
                                                  ? "155px"
                                                  : !page.disabled
                                                  ? "191px"
                                                  : "207.88px",
                                              display: "-webkit-box",
                                              WebkitLineClamp: "3",
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {page.title?.split(". ")[1] ??
                                              page.title ??
                                              ""}
                                          </span>
                                          {page.bookmarkId &&
                                          tabIndex === undefined ? (
                                            <>
                                              <i
                                                className="pi pi-trash"
                                                style={{
                                                  right: "50px",
                                                  top: "20px",
                                                  position: "absolute",
                                                  cursor: "pointer",
                                                  zIndex: "10",
                                                  color: "red",
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  HandleClick.logClickEvents(
                                                    `novo_remover_bookmark`,
                                                    () => {
                                                      confirmRef.current?.toggle(
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                              ></i>
                                              <OverlayPanel ref={confirmRef}>
                                                <div>
                                                  Confirmar?
                                                  <div>
                                                    <Button
                                                      icon="pi pi-check"
                                                      severity="success"
                                                      style={{ width: "100%" }}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        HandleClick.logClickEvents(
                                                          "reports_confirma-remover-filtro-favorito",
                                                          () => {
                                                            deleteBookmark(
                                                              page.bookmarkId ??
                                                                0
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </OverlayPanel>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {!page.disabled ? (
                                            <i
                                              className="pi pi-external-link"
                                              style={{
                                                right: "15px",
                                                top: "15px",
                                              }}
                                              onClick={(e) => {
                                                if (page.disabled) {
                                                  return;
                                                }
                                                HandleClick.logClickEvents(
                                                  `novo_relatorios_abrir-externo`,
                                                  () =>
                                                    selecionaPagina(
                                                      e,
                                                      page,
                                                      true
                                                    )
                                                );
                                              }}
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            display: "-webkit-box",
                                            WebkitLineClamp:
                                              page?.title?.length < 30
                                                ? "5"
                                                : page?.title?.length < 50
                                                ? "4"
                                                : "3",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                          }}
                                          title={page.description}
                                        >
                                          {page.description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* TODO: Ainda precisa definir melhor como contatar esses pedidos */}
                                  {/* {page.demo && page.disabled ? (
                                    <div
                                      style={{
                                        padding: "10px",
                                        backgroundColor:
                                          !askPermission.includes(page.id!)
                                            ? "#0076b3"
                                            : "white",
                                        textAlign: "center",
                                        color: !askPermission.includes(page.id!)
                                          ? "white"
                                          : "#0076b3",
                                        fontWeight: "bold",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        cursor: "pointer",
                                        border: !askPermission.includes(
                                          page.id!
                                        )
                                          ? "none"
                                          : "1px solid #0076b3",
                                      }}
                                      onClick={() => {
                                        setAscPermission([
                                          ...askPermission,
                                          page.id!,
                                        ]);
                                      }}
                                    >
                                      {askPermission.includes(page.id!)
                                        ? "Pedido realizado com sucesso"
                                        : "Pedir acesso ao relatório"}
                                    </div>
                                  ) : (
                                    <></>
                                  )} */}
                                </div>
                              </Fragment>
                            );
                          })}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getBookmarks();
    const user = TokenService.getUserInfo();
    if (user) {
      setUserInfo(user);
    }
    const elem = document.querySelector('.items')
    elem?.addEventListener("scroll", (event) => {
      setScrolled(true);      
    });
    return () => {elem?.removeEventListener('scroll', () => {
      //
    })}
  }, []);

  useEffect(() => {
    if (filterTxt === "") {
      setFilteredPagesOptions([]);
      return;
    }
    const items = pagesOptions.filter(
      (item: any) =>
        item.title?.toLowerCase().includes(filterTxt.toLowerCase()) ||
        item.description?.toLowerCase().includes(filterTxt.toLowerCase())
    );
    const reportTypeIdList = items.map((item: any) => item.reportTypeId);
    setFilteredPagesOptions(items);
    if (items.length > 0) {
      checkTabIndex(reportTypeIdList);
    } else {
      setActiveTabIndex(1);
    }
  }, [filterTxt]);

  return (
    <>
      {loading ? <LoadingComp /> : <></>}
      <Toast ref={toastRef} />
      <div className="selector-card" style={selectorStyle}>
        <div className="selector--body">
          <div className="content">
            {showRetornaBtn ? (
              <div className="indicador-acao">
                <Button
                  label="Voltar"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  data-cy="relatorio-voltar"
                  onClick={() =>
                    HandleClick.logClickEvents("relatorios_voltar", retornarGeo)
                  }
                />
                <span style={{ marginRight: "10px", color: "darkgray" }}>
                  |
                </span>
                <span>
                  Para <strong>avançar</strong> selecione um relatório abaixo.
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className="configs">
              <div>
                <span>
                  <InputText
                    id="busca"
                    placeholder="Busque um relatório"
                    value={filterTxt}
                    onChange={(e) => {
                      setFilterTxt(e.target.value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="tabs-report">
              <TabView
                className="page-selector-tabview"
                style={{height: '100%'}}
                activeIndex={activeTabIndex}
                onTabChange={(e) => setActiveTabIndex(e.index)}
              >
                {sectorTabs.map((sectors, index) => {
                  if (index === 0) {
                    return (
                      <TabPanel key={sectors.title} header={sectors.title}>
                        {getTabItems(
                          bookmarks?.map((item) => {
                            return {
                              title: item.title,
                              description: item.description,
                              id: item.pageId,
                              section: "1. Salvos",
                              link: item.link,
                              bookmarkId: item.id,
                            };
                          }) as Pagina[]
                        )}
                      </TabPanel>
                    );
                  }
                  return sectors.title !== "Customizações" ? (
                    <TabPanel
                      key={sectors.title}
                      header={sectors.title}
                      disabled={
                        pagesOptions.filter(
                          (page) => page.reportTypeId === sectors.reportTypeId
                        ).length > 1
                          ? false
                          : true
                      }
                    >
                      {filterTxt !== ""
                        ? getTabItems(
                            filteredPagesOptions.filter(
                              (page) =>
                                page.reportTypeId === sectors.reportTypeId
                            )
                          )
                        : getTabItems(
                            pagesOptions.filter(
                              (page) =>
                                page.reportTypeId === sectors.reportTypeId
                            )
                          )}
                    </TabPanel>
                  ) : (
                    <TabPanel
                      key={sectors.title}
                      header={sectors.title}
                      disabled={
                        pagesOptions.filter(
                          (page) =>
                            page.reportTypeId !== 1000 &&
                            page.reportTypeId !== 1271 &&
                            page.reportTypeId !== 1272 &&
                            page.reportTypeId !== 1273 &&
                            page.reportTypeId !== 1274 &&
                            page.reportTypeId !== 1397
                        ).length >= 1
                          ? false
                          : true
                      }
                    >
                      {filterTxt !== ""
                        ? getTabItems(
                            filteredPagesOptions.filter(
                              (page) =>
                                page.reportTypeId !== 1000 &&
                                page.reportTypeId !== 1271 &&
                                page.reportTypeId !== 1272 &&
                                page.reportTypeId !== 1273 &&
                                page.reportTypeId !== 1274 &&
                                page.reportTypeId !== 1397
                            )
                          )
                        : getTabItems(
                            pagesOptions.filter(
                              (page) =>
                                page.reportTypeId !== 1000 &&
                                page.reportTypeId !== 1271 &&
                                page.reportTypeId !== 1272 &&
                                page.reportTypeId !== 1273 &&
                                page.reportTypeId !== 1274 &&
                                page.reportTypeId !== 1397
                            )
                          )}
                    </TabPanel>
                  );
                })}
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
