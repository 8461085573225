import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useHashStore = create(
  subscribeWithSelector((set) => ({
    hash: {},
    addFilterHash: (hash: string) =>
      set((state: any) => ({
        hash: { ...state.hash, ...{ hash_filter: hash } },
      })),
    addParamHash: (hash: string) =>
      set((state: any) => ({
        hash: { ...state.hash, ...{ hash_page_params: hash } },
      })),
  }))
);

export default useHashStore;
