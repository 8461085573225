import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./api";
import { Ranking } from "interfaces/ranking";

const getUserPageRanking = async (
  userId: number | undefined,
  pageId: any
): Promise<AxiosResponse<Ranking[]>> => {
  return api.get(`/ranking/${userId}/${pageId}`)
}

const vote = async (
  userId: any,
  pageId: any,
  title: any,
  vote: any
): Promise<AxiosResponse> => {
  return api.post(`/ranking/include`, {title, userId, pageId, vote})
}

const RankingService = {
  getUserPageRanking,
  vote
}

export default RankingService