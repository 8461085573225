import React from "react";
import "./Loading.scss";

export default function LoadingComp(): React.ReactElement {
    return (
        <div className="overlay-loading-bg" data-cy="loading-overlay">
          <div className="overlay-card">
            <div
              className="overlay-loading"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/images/preloader.gif"
                })`,
              }}
            ></div>
            <span>Carregando conteúdo</span>
          </div>
        </div>
    )
}