import HandleClick from "middleware/handle-click";
import { Button } from "primereact/button";
import React, { ReactElement, useEffect, useState } from "react";
import TokenService from "services/token.service";

export default function BeamerButton(): ReactElement {
  const userInfo = TokenService.getUserInfo();

  return (
    <>
      <div style={{ position: "relative" }}>
        {userInfo?.permissionCount ? (
          <Button
            style={{
              color: "#0076b3",
              width: "40px",
              height: "40px",
              padding: "0px",
              border: "none",
              overflow: "visible"
            }}
            outlined
            tooltip="Novidades"
            tooltipOptions={{ position: 'bottom' }}
            id="beamer-btn"
            icon="pi pi-bell"
            onClick={() => HandleClick.logClickEvents("navbar_beamer")}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
