import User from "interfaces/user";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GeografiaService from "services/geografia.service";
import UserService from "services/user.service";
import "../SeletorDashboard.scss";
import HandleClick from "middleware/handle-click";
import SelectorSessionCard from "../SelectorSessionCard";
import TokenService from "services/token.service";

const GeografiaSeletor = ({
  selecionaGeografia,
  showRetornaBtn,
  cid,
  listStyle,
}: any): React.ReactElement => {
  const [geografiasOptions, setGeografiaOptions] = useState<any[]>([]);
  const [geografias, setGeografias] = useState<any[]>([]);
  const [geografiasGrouped, setGeografiasGrouped] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<User>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const retornarCat = (): void => {
    navigate("/categoria");
  };

  const [hover, setHover] = useState(false);
  const onHover = (id: any): void => {
    setHover(id);
  };

  const onLeave = (): void => {
    setHover(false);
  };

  const removeDuplicates = (values: any[]): any[] => {
    const notDuplicate: any[] = [];
    values.forEach((value) => {
      if (notDuplicate.length === 0) {
        notDuplicate.push(value);
      }
      if (!notDuplicate.find((item) => item.name === value.name)) {
        notDuplicate.push(value);
      }
    });
    return notDuplicate;
  };

  const extrairGeografia = (geografias: any[]): void => {
    const group = removeDuplicates(geografias).reduce((acc, item) => {
      if (!acc[item.region]) {
        acc[item.region] = [];
      }
      acc[item.region].push(item);
      return acc;
    }, []);
    let geografiasGrouped = [];
    for (const key in group) {
      group[key].sort((a: { name: number }, b: { name: number }) => {
        return a.name >= b.name ? 1 : -1;
      });
      geografiasGrouped.push({ title: key, items: group[key] });
    }
    geografiasGrouped = geografiasGrouped.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setGeografias(removeDuplicates(geografias));
    setGeografiasGrouped(geografiasGrouped);
  };

  const getGeografias = async (): Promise<void> => {
    const catId = cid ? cid : searchParams.get("cid") ?? "";
    setLoading(true);
    try {
      const response = await GeografiaService.getGeografiaByCategoria(catId);
      if (response.status === 200 && response.data.length === 0) {
        retornarCat();
      }
      if (response.status === 200) {
        setGeografiaOptions(response.data);
        extrairGeografia(response.data);
      }
    } catch (error) {
      console.error(error);
      navigate("/categoria");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "V360 - Seleção Geografia";
    if (geografias.length === 0) {
      getGeografias();
    }
    const user = TokenService.getUserInfo();
    if (user) {
      setUserInfo(user);
    }
  }, []);

  const skeletonItem = (): React.ReactElement => {
    return (
      <div className="item--card">
        <Skeleton></Skeleton>
        <Skeleton height="200px"></Skeleton>
      </div>
    );
  };

  const getSkeleton = (): React.ReactElement => {
    return (
      <>
        <div className="setor" data-cy="geografia-loading">
          <div className="setor--header">
            <Skeleton></Skeleton>
          </div>
          <div className="setor--body">{skeletonItem()}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="selector-card">
        <div className="selector--body">
          <div className="content">
            {showRetornaBtn ? (
              <div className="indicador-acao">
                <Button
                  label="Voltar"
                  icon="pi pi-arrow-left"
                  iconPos="left"
                  data-cy="geografia-voltar"
                  onClick={() =>
                    HandleClick.logClickEvents("geografias_voltar", retornarCat)
                  }
                />
                <span style={{ marginRight: "10px", color: "darkgray" }}>
                  |
                </span>
                <span>
                  Para <strong>avançar</strong> selecione uma geografia abaixo.
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className="configs" style={{ display: "none" }}>
              <div>
                <span>
                  <InputText
                    id="busca"
                    placeholder="Busque uma região ou estado"
                    value={""}
                  />
                </span>
              </div>
            </div>
            <div className="items" style={listStyle}>
              {loading ? (
                getSkeleton()
              ) : geografiasGrouped.length === 0 ? (
                <>
                  <h3> Nenhuma geografia disponível! </h3>
                </>
              ) : (
                <SelectorSessionCard
                  itemArray={geografiasGrouped}
                  selectedItem={selecionaGeografia}
                  prefix={"geografias"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeografiaSeletor;
