const getActualDateAsString = (): string => {
  const date = new Date();
  return date
    .toLocaleString("pt-BR")
    .replace(new RegExp("/", "g"), "-")
    .replace(new RegExp(":", "g"), "-")
    .replace(",", "")
    .replace(" ", "_");
};

const diffInHours = (date1: Date, date2: Date): number => {
  return Math.abs(date1.getTime() - date2.getTime()) / 3600000;
};

const DataUtils = {
  getActualDateAsString,
  diffInHours,
};

export default DataUtils;
