import { create } from "zustand";

const initialState = {
  params: {},
  waitChain: false
};

const useSelectedParamStore = create((set) => ({
  ...initialState,
  addParams: (params: any) => set((state: any) => ({ params: params })),
  changeWaitChain: (value: boolean) => set((state: any) => ({ waitChain: value})),
  reset: () => {
    set({
      params: {},
      waitChain: false,
    });
  },
}));

export default useSelectedParamStore;
