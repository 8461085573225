import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./api";
import PageBookmark from "interfaces/pageBookmark";
import Pagina from "interfaces/Pagina";

const getPageInfo = async (
  reportId: string,
  pageId: string
): Promise<AxiosResponse> => {
  return api.get(`/reports/${reportId}/pages/${pageId}`);
};

const getPageUsingQueryId = async (
  queryId: string
) : Promise<AxiosResponse> => {
  return api.get(`/page/query/${queryId.toString()}`);
}

const saveBookmark = async (bookmarkToSave: PageBookmark): Promise<AxiosResponse> => {
  return api.post(`/page/bookmark`, bookmarkToSave);
}

const getBookmarkByLink = async (link: string): Promise<AxiosResponse> => {
  return api.get(`/page/bookmarks/by-link?link=${link}`);
}

const getBookmarks = async (catId: number, geo: string): Promise<AxiosResponse> => {
  return api.get(`/page/bookmarks?categoryId=${catId}&geography=${geo}`);
}

const deleteBookmark = async (link: string): Promise<AxiosResponse> => {
  return api.delete(`/page/bookmarks?link=${link}`);
}

const deleteBookmarkById = async (id: number): Promise<AxiosResponse> => {
  return api.delete(`/page/bookmarks/${id}`);
}

const getAllPaginas = async (): Promise<AxiosResponse> => {
  return api.get("page");
};

const atualizarPagina = async (pagina: Pagina): Promise<AxiosResponse> => {
  return api.put(`page/${pagina.id}`, pagina);
};

const salvarPagina = async (pagina: Pagina): Promise<AxiosResponse> => {
  return api.post("page", pagina);
};

const excluirPagina = async (paginaId: number): Promise<AxiosResponse> => {
  return api.delete(`page/${paginaId}`);
};

const getPagesByCatAndGeo = (catId: string, geo: string): Promise<AxiosResponse> => {
  return api.get(`page/get-all-filtered?categoryId=${catId}&geography=${geo}`);
}

const getPagesByFilters = (
  page: number,
  pageSize: number,
  sort: any[],
  filter = ""
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }

  config.params.append("page", page ? page : 0)
  config.params.append("pageSize", pageSize)
  if(sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc": "desc"},${item.field}`
      )
    })
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }

  return api.get(`/page/search`, config)

}

const getPagesWhereParamsAreUsed = async (
  ids = []
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  if (ids.length > 0) {
    config.params.append("paramList", ids.toString());
  }

  return api.get('/page/get-by-params', config)

}

const PageService = {
  getPageInfo,
  getPageUsingQueryId,
  saveBookmark,
  getBookmarkByLink,
  getBookmarks,
  deleteBookmark,
  getAllPaginas,
  salvarPagina,
  atualizarPagina,
  excluirPagina,
  getPagesByCatAndGeo,
  deleteBookmarkById,
  getPagesByFilters,
  getPagesWhereParamsAreUsed
};
export default PageService;
