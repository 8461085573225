import React, { ReactElement, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Button } from "primereact/button";
import HandleClick from "middleware/handle-click";

function FeedbackButton(): ReactElement {
  const feedback = Sentry.feedbackIntegration({
    // Disable the injection of the default widget
    autoInject: false,
    isNameRequired: true,
    isEmailRequired: true,
    triggerLabel: "Reporte um Bug",
    triggerAriaLabel: "Reporte um Bug",
    formTitle: "Reporte um Bug",
    submitButtonLabel: "Enviar",
    cancelButtonLabel: "Cancelar",
    confirmButtonLabel: "Confirmar",
    addScreenshotButtonLabel: "Adicionar captura de tela",
    removeScreenshotButtonLabel: "Remover captura de tela",
    nameLabel: "Nome",
    namePlaceholder: "Seu nome",
    emailLabel: "E-mail",
    isRequiredLabel: "(obrigatório)",
    messageLabel: "Descrição",
    messagePlaceholder:
      "Qual foi o problema, se possível envie uma captura de tela",
    successMessageText:
      "Obrigado por reportar o problema, em breve alguém entrará em contato",
  });
  // Read `getFeedback` on the client only, to avoid hydration errors during server rendering
  useEffect(() => {
    feedback?.attachTo(document.querySelector("#feedback-btn") ?? "", {
      formTitle: "Report a Bug!",
    });
  }, []);

  return (
    <Button
      id="feedback-btn"
      tooltip="Reportar BUG"
      data-cy="menu-feedback"
      tooltipOptions={{ position: "bottom" }}
      style={{
        color: "#0076b3",
        border: "none",
        width: "40px",
        height: "40px",
        padding: "0px",
        margin: "0 5px"
      }}
      outlined
      icon="pi pi-megaphone"
    />
  );
}

export default Sentry.withProfiler(FeedbackButton);
