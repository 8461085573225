import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./api";

const getGeografiaByCategoria = async (
  categoriaId: string
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("categoryId", categoriaId);
  return api.get(`/data_source/by-category`, config);
};

const GeografiaService = {
  getGeografiaByCategoria,
};

export default GeografiaService;
