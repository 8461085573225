import sandboxshot from "components/sandboxshot/sandboxshot.js";

// singleton service
export class ScreenShotService {
    private static instance: ScreenShotService;

    private constructor() {
        this.openScreenshotArea = (): void => {
            sandboxshot.openBox();
        }
    }
    
    public openScreenshotArea;
    public static getInstance(): ScreenShotService {
        if(!ScreenShotService.instance) {
            ScreenShotService.instance = new ScreenShotService();
        }
        return ScreenShotService.instance;
    }
}