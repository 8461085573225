import AmostraGratisBanner from "components/banners/AmostraGratisBanner";
import HandleClick from "middleware/handle-click";

import React, { useEffect, useState } from "react";

export default function SelectorSessionCard({
  itemArray,
  selectedItem,
  prefix,
}: any): React.ReactElement {
  
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const elem = document.querySelector('.items')
    elem?.addEventListener("scroll", (event) => {
      setScrolled(true);      
    });
    return () => {elem?.removeEventListener('scroll', () => {
      //
    })}
  }, [])

  useEffect(() => {
    if(!window.location.pathname.includes('/categoria') && !window.location.pathname.includes('/geografia')) {
      itemArray.map((itemSession: any) => {
        itemSession?.items?.map((item: any) => {
          const selectorMarkers = sessionStorage.getItem("selector-markers") ?? "{}";
          if((item.geography == JSON.parse(selectorMarkers)['geo'] || item.id == JSON.parse(selectorMarkers)['catId']) && !scrolled) {
            document.querySelector(`#G-${item.id}`)?.scrollIntoView();
          }
        })
      })
    }
  }, [itemArray])

  return (
    <>
      {itemArray.map((itemSession: any) => {
        return (
          <div
            key={itemSession.title}
            className="setor"
            style={{
              marginBottom: "10px",
            }}
          >
            <div className="setor--header">
              <h3>{itemSession.title.split(".")[1]}</h3>
            </div>
            <div className="setor--body">
              {itemSession?.items?.map((item: any) => {
                const id = `G-${item.id}`
                return (
                  <div
                    key={item.id}
                    id={id}
                    className="container--card"
                    style={{ position: "relative" }}
                  >
                    <div
                      key={item.id}
                      className={`item--card ${item.demo ? "demo" : ""} ${item.isBeta ? "beta" : ""} ${
                        item.wip ? "wip" : ""
                      }`}
                      title={item.name ?? item.title}
                      onClick={(e) =>
                        HandleClick.logClickEvents(`${prefix}_abrir`, () =>
                          selectedItem(e, item)
                        )
                      }
                    >
                      {item.demo ? <AmostraGratisBanner /> : <></>}
                      <span className="item--header">
                        <span
                          style={{
                            width: "calc(100% - 10px)",
                          }}
                        >
                          {item.name ?? item.title}
                        </span>
                        <i
                          className="pi pi-external-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            HandleClick.logClickEvents(
                              `${prefix}_abrir-externo`,
                              () => selectedItem(e, item, true)
                            )
                          }}
                        ></i>
                      </span>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_HOST}${item.image}`}
                        alt=""
                        width={217}
                        height={200}
                        loading="lazy"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}
