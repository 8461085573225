import HandleClick from "middleware/handle-click";
import { create } from "zustand";

const useHelpCenterStore = create((set) => ({
    visibility: false,
    setVisible: () => {
        HandleClick.logClickEvents("help_center_from_message", () => set((state: any) => ({ visibility: true })));
    },
    setNotVisible: () =>
        set((state: any) => ({ visibility: false }))
  }));
  
  export default useHelpCenterStore;
  