import { AxiosRequestConfig, AxiosResponse } from "axios";
import Empresa from "interfaces/empresa";
import api from "./api";

const getEmpresasComFiltro = async (
  page: number,
  pageSize: number,
  filtro?: string,
  sort?: any[]
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (page) {
    config.params.append("page", page);
  } else {
    config.params.append("page", 0);
  }
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      if (item.field === "commercial_relationship_type") {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},commercialRelationshipType`
        );
      } else {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},${item.field}`
        );
      }
    });
  }
  if (filtro) {
    config.params.append("filter", filtro);
  }
  return api.get(`/company/search`, config);
};

const excluirEmpresa = async (id: number): Promise<any> => {
  return api.delete(`company/${id}`);
};

const getCrttypes = async (): Promise<any> => {
  return api.get("company/crttypes");
};

const atualizarEmpresa = async (empresa: Empresa): Promise<any> => {
  return api.put(`/company/${empresa.id}`, empresa);
};

const salvarEmpresa = async (empresa: Empresa): Promise<any> => {
  return api.post("/company", empresa);
};

const getEmpresaById = async (empresaId: number): Promise<AxiosResponse> => {
  return api.get(`/company/${empresaId}`);
};

const batchEmpresasById = async (batchId: number[]) : Promise<AxiosResponse> => {
  return api.get(`/company/batch/${batchId.join(",")}`)
}

const findAllEmpresas = async () : Promise<AxiosResponse> => {
  return api.get(`/company/all`)
}

const getEmpresaByFilterId = (id: number): Promise<AxiosResponse> => {
  return api.get(`/company/allFilterId/${id}`);
};

const updateFiltroEmpresas = async (
  companyId: number,
  filterId: number
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (companyId) {
    config.params.append("companyId", companyId);
  }
  if (filterId) {
    config.params.append("filterId", filterId);
  }
  return api.put(`/company/updateFilter`, {}, config);
};

const EmpresaService = {
  getEmpresasComFiltro,
  excluirEmpresa,
  getCrttypes,
  atualizarEmpresa,
  salvarEmpresa,
  getEmpresaById,
  batchEmpresasById,
  findAllEmpresas,
  getEmpresaByFilterId,
  updateFiltroEmpresas
};

export default EmpresaService;
