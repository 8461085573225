import { AxiosResponse } from "axios";
import api from "./api";

const sendReleaseNotes = (
  emails: string[],
  companies: number[],
  idTemplate: string,
  category: string,
  replyTo?: any,
  stubs?: any[]
): Promise<AxiosResponse> => {
  return api.post("/actions/notify/release", {
    emails,
    companies,
    idTemplate,
    replyTo,
    category,
    stub: stubs
  });
};

const EmailService = {
  sendReleaseNotes,
};

export default EmailService;
