import { Outlet } from "react-router-dom";
import React, { ReactElement, useState } from "react";
import ReportNavbar from "components/navbar/ReportNavbar";

const Admin = (): ReactElement => {
  return (
    <>
      <ReportNavbar />
      <Outlet />
    </>
  );
};

export default Admin;
