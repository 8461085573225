/* eslint-disable react/no-unescaped-entities */
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import "./EnvioEmail.scss";
import EmpresaService from "services/empresa.service";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import EmailService from "services/email.service";
import { AxiosError, AxiosResponse } from "axios";
import Papa from "papaparse";
import { FileUpload } from "primereact/fileupload";
import { lineBreak } from "html2canvas/dist/types/css/property-descriptors/line-break";
import { InputTextarea } from "primereact/inputtextarea";
import ToastUtils from "utils/toastUtils";

interface form {
  idTemplate: string;
  replyTo?: string;
  companies: number[];
  categoria: string;
  txtEmails: string;
}

export default function EnvioEmail(): ReactElement {
  const emptyForm = {
    idTemplate: "",
    replyTo: "",
    companies: [],
    categoria: "",
    txtEmails: "",
  };
  const emptyFormError = {
    idTemplate: "",
    replyTo: "",
    companies: "",
    categoria: "",
    txtEmails: "",
  };
  const [envioEmail, setEnvioEmail] = useState<form>(emptyForm);
  const [companies, setCompanies] = useState<any[]>([]);
  const [formErrors, setFormErrors] = useState<any>(emptyFormError);
  const toast = useRef<Toast>(null);
  const [emails, setEmails] = useState<string[]>([]);
  const [stub, setStub] = useState<any[]>([]);
  const [btnEnabled, setBtnEnabled] = useState(true);

  const getCompanies = (): void => {
    EmpresaService.getEmpresasComFiltro(0, 100000).then((response) => {
      setCompanies(response.data.content);
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const sendEmail = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    let errorCount = 0;
    const objToSend = { ...envioEmail };
    if (!envioEmail.idTemplate) {
      setFormErrors({
        ...formErrors,
        ...{ idTemplate: "obrigatório" },
      });
      errorCount++;
    }
    if (envioEmail.replyTo) {
      try {
        objToSend.replyTo = JSON.parse(envioEmail.replyTo);
        setFormErrors({ ...formErrors, ...{ replyTo: "" } });
      } catch (error) {
        toast.current?.show({
          ...ToastUtils.defaultErrorOptions,
          ...{ detail: "Verifique o JSON do reply_to" },
        });
        setFormErrors({
          ...formErrors,
          ...{ replyTo: "Verifique o JSON do reply_to" },
        });
        errorCount++;
      }
    }
    if (errorCount) {
      return;
    }
    if (!envioEmail.replyTo) {
      delete objToSend.replyTo;
    }
    objToSend.companies = envioEmail.companies.map(
      (company: any) => company.id
    );
    if (!envioEmail.categoria) {
      objToSend.categoria = "rptf_envio_manual";
    }
    let emailsToSend = [...emails];
    if (envioEmail.txtEmails) {
      emailsToSend = [...emailsToSend, ...envioEmail.txtEmails.split(",")];
    }
    setBtnEnabled(false);
    EmailService.sendReleaseNotes(
      emailsToSend,
      objToSend.companies,
      objToSend.idTemplate,
      objToSend.categoria,
      objToSend.replyTo,
      stub
    )
      .then((response: AxiosResponse) => {
        toast.current?.show({
          ...ToastUtils.defaultSuccessOptions,
          ...{ detail: "Os e-mails foram enviados." },
        });
        const inpFile = document.getElementById("input-file") as any;
        setEmails([]);
        inpFile.value = "";
        setEnvioEmail(emptyForm);
        setFormErrors(emptyFormError);
      })
      .catch((error: AxiosError) => {
        toast.current?.show({
          ...ToastUtils.defaultErrorOptions,
          ...{ detail: "Erro ao enviar o email, contate o suporte" },
        });
      })
      .finally(() => setBtnEnabled(true));
  };

  const handleFileChange = (e: any): void => {
    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const reader = new FileReader();
      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];

      reader.onload = async ({ target }) => {
        const csv: any = Papa.parse(target?.result as any, {
          header: false,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          newline: "\r",
          skipEmptyLines: false,
        });
        const parsedData = csv?.data.slice(1);
        const keys = csv?.data[0];
        const jsonData = parsedData.map((item: string[]) => {
          const value: any = {};
          for (const [index, key] of keys.entries()) {
            value[key] = item[index];
          }
          return value;
        });
        setStub(jsonData);
        setEmails(
          jsonData.map((item: any) => item["email"]?.replace("\n", ""))
        );
      };
      reader.readAsText(inputFile);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        style={{ display: "flex", justifyContent: "center", padding: "15px 0" }}
        className="release-notes"
      >
        <Card style={{ width: "70%" }} title="Disparo de e-mails">
          <p>
            Para o envio do e-mail, é necessário ter o ID do template criado no{" "}
            <a
              href="https://login.sendgrid.com/login/identifier"
              target="_blanck"
            >
              SendGrid
            </a>
          </p>
          <form onSubmit={sendEmail}>
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="report-type-idTemplate"
                value={envioEmail.idTemplate}
                onChange={(e) => {
                  setEnvioEmail({
                    ...envioEmail,
                    ...{ idTemplate: e.target.value },
                  });
                }}
                className={formErrors.idTemplate ? "p-invalid p-block" : ""}
              />
              <label htmlFor="idTemplate">templateId</label>
            </span>
            <p>
              Categoria não é obrigatório e é usado no SendGrid para diferenciar
              os envios e conseguir analisar metricas de envio individuais. Pode
              ser uma `string` qualquer. O valor padrão é "rptf_envio_manual"
            </p>
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="report-type-categoria"
                value={envioEmail.categoria}
                onChange={(e) => {
                  setEnvioEmail({
                    ...envioEmail,
                    ...{ categoria: e.target.value },
                  });
                }}
                className={formErrors.categoria ? "p-invalid p-block" : ""}
              />
              <label htmlFor="idTemplate">Categoria</label>
            </span>
            <p>
              O "replay_to" não é obrigatório, ele é o que define no envio do
              email, quem será o contato de resposta. Padrão é: <br />
              {'{"name": "Varejo 360", "email": "suporte@meuvarejo360.com.br"}'}
            </p>
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="report-type-title"
                value={envioEmail.replyTo}
                onChange={(e) => {
                  setEnvioEmail({
                    ...envioEmail,
                    ...{ replyTo: e.target.value },
                  });
                }}
                className={formErrors.replyTo ? "p-invalid p-block" : ""}
              />
              <label htmlFor="idTemplate">reply_to</label>
            </span>
            <p>Selecione as empresas que receberão o email</p>
            <span className="p-float-label">
              <MultiSelect
                value={envioEmail.companies}
                onChange={(e) => {
                  setEnvioEmail({
                    ...envioEmail,
                    ...{ companies: e.target.value },
                  });
                }}
                options={companies}
                optionLabel="name"
                filter
                maxSelectedLabels={3}
                className="w-full md:w-20rem"
                focusOnHover={false}
              />
              <label htmlFor="ms-cities">Selecione as empresas</label>
            </span>
            <p>
              Ou adicione o csv com os emails, ou escreva os emails{" "}
              <strong>separados por vírgula</strong>.
            </p>
            <p>** A lista enviada, é o conjunto do csv + txt</p>
            <br />
            <p>
              A primeira linha deve ser composta pelas colunas, sendo a coluna{" "}
              <strong>
                <code>email</code>
              </strong>{" "}
              obrigatória
            </p>
            <p>
              As demais colunas são usadas pelo{" "}
              <strong>
                <code>dynamic template</code>
              </strong>{" "}
              do Sendgrid.
            </p>
            <p>Exemplo da coluna:</p>
            <strong>
              <code>Empresa,email,nome,numero_da_sorte_b2b</code>
            </strong>
            <br />
            <p>
              Para emails cadastrados na plataforma, o nome da empresa é
              selecionado automaticamente.
            </p>
            <input
              id="input-file"
              type="file"
              onChange={handleFileChange}
            ></input>
            <InputTextarea
              value={envioEmail.txtEmails}
              onChange={(e) => {
                setEnvioEmail({
                  ...envioEmail,
                  ...{ txtEmails: e.target.value },
                });
              }}
              rows={5}
              cols={30}
            />
            <div
              style={{
                marginTop: "30px",
                width: "200px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                label="Enviar"
                type="submit"
                disabled={!btnEnabled}
                className="p-button-raised"
              />
              <Button
                label="Limpar"
                type="button"
                className="p-button-raised"
                severity="secondary"
                onClick={() => {
                  const inpFile = document.getElementById("input-file") as any;
                  setEmails([]);
                  inpFile.value = "";
                  setEnvioEmail(emptyForm);
                  setFormErrors(emptyFormError);
                }}
              />
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}
