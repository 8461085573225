import { create } from "zustand";

const initialState = {
    opIsOpened: false
};

const useNavBarStore = create((set) => ({
    ...initialState,
    setIsOpened: (params: any) => set((state: any) => ({ params: params }))
}))

export default useNavBarStore