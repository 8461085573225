import TokenService from "./token.service";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface PropsFunction {
  children: ReactElement;
}

export const ProtectUserRoute = ({ children }: PropsFunction): ReactElement => {
  const user = TokenService.getUserInfo();

  if (!TokenService.getLocalAccessToken()) {
    sessionStorage.setItem(
      "originRoute",
      `${window.location.pathname}${window.location.search}`
    );
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    sessionStorage.setItem(
      "originRoute",
      `${window.location.pathname}${window.location.search}`
    );
    return <Navigate to="/login" replace />;
  }

  if (user.reset) {
    sessionStorage.setItem(
      "originRoute",
      `${window.location.pathname}${window.location.search}`
    );
    return <Navigate to="/change-password" replace />;
  }
  return children;
};

export const ProtectAdminRoute = ({
  children,
}: PropsFunction): ReactElement => {
  const user = TokenService.getUserInfo();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (user.reset) {
    return <Navigate to="/change-password" replace />;
  }

  if (!user.admin) {
    return <Navigate to="/categoria" replace />;
  }

  return children;
};
