import { ToastMessage } from "primereact/toast";

const defaultErrorOptions: ToastMessage = {
    life: 10000,
    severity: "error"
}

const defaultSuccessOptions: ToastMessage = {
    life: 10000,
    severity: "success"
}

const defaultWarnOptions: ToastMessage = {
    life: 10000,
    severity: "warn"
}

const ToastUtils = {
    defaultErrorOptions,
    defaultSuccessOptions,
    defaultWarnOptions
}

export default ToastUtils;