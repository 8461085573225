import { create } from "zustand";

type State = {
  aplicados: any;
  selecionados: any;
  selecionadosExceto: any;
  aplicadosExceto: any;
};

const initialState: State = {
  aplicados: {},
  selecionados: {},
  selecionadosExceto: [],
  aplicadosExceto: [],
};

const useFiltrosStore = create<State>((set) => ({
  ...initialState,
  setAplicado: (aplicados: any) =>
    set((state: any) => ({ aplicados: aplicados, selecionados: aplicados })),
  setSelecionado: (selecionados: any) =>
    set((state: any) => ({ selecionados: selecionados })),
  addAplicadosExceto: (valor: string) =>
    set((state: any) => ({
      aplicadosExceto: [...state.aplicadosExceto, ...[valor]],
    })),
  addSelecionadosExceto: (valor: string) =>
    set((state: any) => ({
      selecionadosExceto: [...state.selecionadosExceto, ...[valor]],
    })),
  reset: () => {
    set(initialState);
  },
}));

export default useFiltrosStore;
