import React, { ReactElement } from "react";
import ReactDOM from "react-dom/client";

// PrimeReact imports
import "primereact/resources/themes/lara-light-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons

// AgGrid imports
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import "./index.scss";
import Admin from "./pages/admin/Admin";
import {
  Navigate,
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
} from "react-router-dom";
import {
  ProtectAdminRoute,
  ProtectUserRoute,
} from "services/protected-routes.service";
import TokenService from "services/token.service";
import EnvioEmail from "pages/cadastros/envioEmail/EnvioEmail";
import DiagnosticoPage from "pages/diagnostico/Diagnostico";
import * as Sentry from "@sentry/react";
import { addLocale, PrimeReactProvider } from "primereact/api";

// AG Grid Licence
LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  "CompanyName=BENCHMKT INTELIGENCIA E PESQUISAS DE MERCADO LTDA,LicensedApplication=Plataforma Varejo 360,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021674,ExpiryDate=18_December_2022_[v2]_MTY3MTMyMTYwMDAwMA==0ac60460f0519e795d7b6e2d1e0e4ce8"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyC-n3nYCydZ-bPRQJ7Xdv2DYdQFKV_f_84",
  authDomain: "v360-platform.firebaseapp.com",
  projectId: "v360-platform",
  storageBucket: "v360-platform.firebasestorage.app",
  messagingSenderId: "965740693862",
  appId: "1:965740693862:web:fde62e50656d33257660a7",
  measurementId: "G-E208Q94YRS"
};

interface PropsFunction {
  children: ReactElement;
}

Sentry.init({
  dsn: "https://2c771de9e8296fc62a36325a8746e630@o425066.ingest.us.sentry.io/4506388612579328",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      mask: ['.mask-sentry']
    })
  ],
  beforeSend: (event, hint) => {
    // see all errors, what you wants. 
    // using console.log(hint.originalException)

    // for example, not send when error code 404 when using axios
    const { response }: any = hint.originalException
    if (
      response && response.status && (response.status === 401 || response.status === 403)
    ) {
      return null
    }
    return event
  },
  autoSessionTracking: true,
  release: `plataforma@v${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development' ? 0.0 : 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development' ? 0.0 : .1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development' ? 0.0 : .6, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const CheckIfLogged = ({ children }: PropsFunction): ReactElement => {
  if (!TokenService.getLocalAccessToken()) {
    return children;
  }
  return <Navigate to="/categoria" replace />;
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);


const UsuariosPage = React.lazy(
  () => import("./pages/cadastros/usuarios/UsuariosPage")
);

const EmpresasPage = React.lazy(
  () => import("./pages/cadastros/empresas/EmpresasPage")
);

const CategoriasPage = React.lazy(
  () => import("./pages/cadastros/categorias/Categorias")
);

const RelatoriosPage = React.lazy(
  () => import("./pages/cadastros/relatorios/RelatoriosPage")
);

const PaginasCadPage = React.lazy(
  () => import("./pages/cadastros/paginas/CadastroPagina")
);

const ConsultasPage = React.lazy(
  () => import("./pages/cadastros/consultas/ConsultasPage")
);

const FiltrosPage = React.lazy(
  () => import("./pages/cadastros/filtros/FiltrosPage")
);

const ParametrosPage = React.lazy(
  () => import("./pages/cadastros/parametros/ParametrosPage")
);

const LoginPage = React.lazy(() => import("./pages/login/Login"));

const ResetSenhaPage = React.lazy(
  () => import("./pages/reset-senha/Reset-senha")
);

const ReportPage = React.lazy(() => import("./pages/painel/report/Report"));

const CategoriaPage = React.lazy(
  () => import("./pages/painel/seletor/categoria/CategoriaPage")
);

const GeografiaPage = React.lazy(
  () => import("./pages/painel/seletor/geografia/GeografiaPage")
);
const PaginasPage = React.lazy(
  () => import("./pages/painel/seletor/pagina/PaginaPage")
)

const router = sentryCreateBrowserRouter([
  {
    path: "/admin",
    element: (
      <ProtectAdminRoute>
        <Admin />
      </ProtectAdminRoute>
    ),
    children: [
      {
        path: "usuarios",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <UsuariosPage />
          </React.Suspense>
        ),
      },
      {
        path: "empresas",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <EmpresasPage />
          </React.Suspense>
        ),
      },
      {
        path: "categorias",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <CategoriasPage />
          </React.Suspense>
        ),
      },
      {
        path: "relatorios",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <RelatoriosPage />
          </React.Suspense>
        ),
      },
      {
        path: "paginas",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <PaginasCadPage />
          </React.Suspense>
        ),
      },
      {
        path: "consultas",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ConsultasPage />
          </React.Suspense>
        ),
      },
      {
        path: "filtros",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <FiltrosPage />
          </React.Suspense>
        ),
      },
      {
        path: "parametros",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ParametrosPage />
          </React.Suspense>
        ),
      },
      {
        path: "report/:reportId/page/:pageId",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ReportPage />
          </React.Suspense>
        ),
      },
      {
        path: "disparo-email",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <EnvioEmail />
          </React.Suspense>
        ),
      },
      {
        path: "*",
        element: <Navigate replace to="empresas" />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <CheckIfLogged>
        <React.Suspense fallback={<>Carregando</>}>
          <LoginPage />
        </React.Suspense>
      </CheckIfLogged>
    ),
  },
  {
    path: "/diagnostico",
    element: <DiagnosticoPage></DiagnosticoPage>,
  },
  {
    path: "categoria",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>Carregando</>}>
          <CategoriaPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "geografia",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>Carregando</>}>
          <GeografiaPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "pages",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>Carregando</>}>
          <PaginasPage />
        </React.Suspense>
      </ProtectUserRoute>
    )
  },
  {
    path: "reports/:reportId/page/:pageId",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>Carregando</>}>
          <ReportPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "/change-password",
    element: (
      <React.Suspense fallback={<>Carregando</>}>
        <ResetSenhaPage />
      </React.Suspense>
    ),
  },
  {
    path: "/",
    element: <Navigate replace to="/categoria" />,
  },
  {
    path: "*",
    element: <Navigate replace to="/login" />,
  },
]);

const reactRouterFutureConfigs = {
  v7_startTransition: true,
  v7_relativeSplatPath: true
}

addLocale('pt-br', {
  "accept": "Sim",
  "addRule": "Adicionar Regra",
  "am": "AM",
  "apply": "Aplicar",
  "cancel": "Cancelar",
  "choose": "Escolher",
  "chooseDate": "Escolher Data",
  "chooseMonth": "Escolher Mês",
  "chooseYear": "Escolher Ano",
  "clear": "Limpar",
  "contains": "Contém",
  "custom": "Personalizado",
  "dateAfter": "Data depois de",
  "dateBefore": "Data antes de",
  "dateFormat": "dd/MM/yy",
  "dateIs": "Data é",
  "dateIsNot": "Data não é",
  "dayNames": [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
  ],
  "dayNamesMin": [
    "D",
    "S",
    "T",
    "Q",
    "Q",
    "S",
    "S"
  ],
  "dayNamesShort": [
    "Dom",
    "Seg",
    "Ter",
    "Qua",
    "Qui",
    "Sex",
    "Sáb"
  ],
  "emptyFilterMessage": "Nenhum resultado encontrado",
  "emptyMessage": "Nenhuma opção disponível",
  "endsWith": "Termina com",
  "equals": "Igual",
  "fileSizeTypes": [
    "B",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB"
  ],
  "filter": "Filtro",
  "firstDayOfWeek": 0,
  "gt": "Maior que",
  "gte": "Maior ou igual a",
  "lt": "Menor que",
  "lte": "Menor ou igual a",
  "matchAll": "Corresponder Todos",
  "matchAny": "Corresponder Qualquer",
  "medium": "Médio",
  "monthNames": [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ],
  "monthNamesShort": [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ],
  "nextDecade": "Próxima Década",
  "nextHour": "Próxima Hora",
  "nextMinute": "Próximo Minuto",
  "nextMonth": "Próximo Mês",
  "nextSecond": "Próximo Segundo",
  "nextYear": "Próximo Ano",
  "noFilter": "Sem Filtro",
  "notContains": "Não contém",
  "notEquals": "Diferente",
  "now": "Agora",
  "passwordPrompt": "Digite uma senha",
  "pending": "Pendente",
  "pm": "PM",
  "prevDecade": "Década Anterior",
  "prevHour": "Hora Anterior",
  "prevMinute": "Minuto Anterior",
  "prevMonth": "Mês Anterior",
  "prevSecond": "Segundo Anterior",
  "prevYear": "Ano Anterior",
  "reject": "Não",
  "removeRule": "Remover Regra",
  "startsWith": "Começa com",
  "strong": "Forte",
  "today": "Hoje",
  "upload": "Enviar",
  "weak": "Fraco",
  "weekHeader": "Sem",
  "aria": {
    "cancelEdit": "Cancelar Edição",
    "close": "Fechar",
    "collapseRow": "Recolher Linha",
    "editRow": "Editar Linha",
    "expandRow": "Expandir Linha",
    "falseLabel": "Falso",
    "filterConstraint": "Restrição de Filtro",
    "filterOperator": "Operador de Filtro",
    "firstPageLabel": "Primeira Página",
    "gridView": "Visualização de Grade",
    "hideFilterMenu": "Esconder Menu de Filtro",
    "jumpToPageDropdownLabel": "Ir para a Página",
    "jumpToPageInputLabel": "Ir para a Página",
    "lastPageLabel": "Última Página",
    "listView": "Visualização de Lista",
    "moveAllToSource": "Mover Todos para a Origem",
    "moveAllToTarget": "Mover Todos para o Destino",
    "moveBottom": "Mover para o Final",
    "moveDown": "Mover para Baixo",
    "moveTop": "Mover para o Topo",
    "moveToSource": "Mover para a Origem",
    "moveToTarget": "Mover para o Destino",
    "moveUp": "Mover para Cima",
    "navigation": "Navegação",
    "next": "Próximo",
    "nextPageLabel": "Próxima Página",
    "nullLabel": "Não Selecionado",
    "otpLabel": "Insira o caractere da senha de uso único {0}",
    "pageLabel": "Página {page}",
    "previous": "Anterior",
    "previousPageLabel": "Página Anterior",
    "rotateLeft": "Rotacionar para a Esquerda",
    "rotateRight": "Rotacionar para a Direita",
    "rowsPerPageLabel": "Linhas por página",
    "saveEdit": "Salvar Edição",
    "scrollTop": "Rolar para o Topo",
    "selectAll": "Todos os itens selecionados",
    "selectRow": "Linha Selecionada",
    "showFilterMenu": "Mostrar Menu de Filtro",
    "slide": "Deslizar",
    "slideNumber": "Slide {slideNumber}",
    "star": "1 estrela",
    "stars": "{star} estrelas",
    "trueLabel": "Verdadeiro",
    "unselectAll": "Todos os itens desmarcados",
    "unselectRow": "Linha Desmarcada",
    "zoomImage": "Ampliar Imagem",
    "zoomIn": "Ampliar",
    "zoomOut": "Reduzir"
  }
});

const value = {
  ripple: true
};

root.render(
  <PrimeReactProvider value={value}>
    <RouterProvider router={router} future={reactRouterFutureConfigs} />
  </PrimeReactProvider>
);
if(process.env.REACT_APP_ENV === 'production') {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}
