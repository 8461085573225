import User from "interfaces/user";
import UserService from "./user.service";

let buscandoUsuario = false;

const getLocalRefreshToken = (): string => {
  const localToken = localStorage.getItem(
    `refreshToken${process.env.REACT_APP_ENV}`
  );
  return localToken || "";
};

const getLocalAccessToken = (): string => {
  const localToken = localStorage.getItem(`token${process.env.REACT_APP_ENV}`);
  return localToken || "";
};

const setLocalToken = (tokenData: any): void => {
  const date = new Date();
  localStorage.setItem(`token${process.env.REACT_APP_ENV}`, tokenData.token);
  localStorage.setItem(
    `refreshToken${process.env.REACT_APP_ENV}`,
    tokenData.refreshToken
  );
};

const setUserInfo = (userInfo: User): void => {
  localStorage.setItem("user", JSON.stringify(userInfo));
};

const getUserInfo = (): User | void => {
  const user = localStorage.getItem("user");
  if(!user) {
    if(!buscandoUsuario && !window.location.href.includes("login")) {
      buscandoUsuario = true;
      UserService.getUserInfo()
        .then(data => JSON.parse(data))
        .catch(error => console.error(error))
        .finally(() => {
          buscandoUsuario = false
        })
    }
  } else {
    return JSON.parse(user ?? "");
  }
};

const getUserId = (): number => {
  const user = getUserInfo();
  return user?.id || 0;
};

const removeToken = (navigator?: any): void => {
  localStorage.clear();
  sessionStorage.clear();
  const sulfix = process.env.REACT_APP_ENV;
  window.localStorage.clear();
  window.sessionStorage.clear();
  if (navigator) {
    navigator("/login", { replace: true });
  }
};

const saveReports = (reports: any): void => {
  localStorage.setItem("reports", JSON.stringify(reports));
};

const getReports = (): string | null => {
  const string = localStorage.getItem("reports");
  if (string) {
    return JSON.parse(string);
  }
  return null;
};

const removeReports = (): void => {
  localStorage.removeItem("reports");
};

const TokenService = {
  getLocalAccessToken,
  getLocalRefreshToken,
  getUserInfo,
  setLocalToken,
  setUserInfo,
  removeToken,
  saveReports,
  getReports,
  removeReports,
  getUserId,
};

export default TokenService;
