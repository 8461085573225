import React from "react";

const AmostraGratisBanner = (): React.ReactElement => {
  return (
    <>
      <span className="reports__grid__section__content--demo-banner-text">
        AMOSTRA GRÁTIS
      </span>
      <svg
        width="171px"
        height="194px"
        className="reports__grid__section__content--demo-banner-svg"
      >
        <rect
          width="140"
          height="25"
          className="reports__grid__section__content--demo-banner-rect"
        />
        Desculpe, Seu navegador não suporta SVG.
      </svg>
    </>
  );
};

export default AmostraGratisBanner;
