import useShowCleanerAlertStore from "pages/painel/report/store/ShowCleanerAlert";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import React, { ReactElement, useState } from "react";

export default function DialogLimpezaHashes(): ReactElement {
    const [alertaChecked, setAlertachecked] = useState(false);
    const showAlertaResetParam = useShowCleanerAlertStore(
        (state: any) => state.show
    );
    const setShowAlertaResetParam = useShowCleanerAlertStore(
        (state: any) => state.setShow
    );
    const [neverShowAlertaResetParam, setNeverShowAlertaResetParam] =
        useState<boolean>(
        window.localStorage.getItem("showAlertaResetParam") === "true" || false
    );
    const renderFooter = (): React.ReactElement => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="field-checkbox">
              <Checkbox
                inputId="alertaChecked"
                onChange={(e: any) => setAlertachecked(e.checked)}
                checked={alertaChecked}
              ></Checkbox>
              <label htmlFor="alertaChecked" style={{ marginLeft: "10px" }}>
                Não exibir novamente
              </label>
            </div>
            <Button
              label="Fechar"
              icon="pi pi-check"
              onClick={() => {
                setShowAlertaResetParam(false);
                if (alertaChecked) {
                  window.localStorage.setItem("showAlertaResetParam", "true");
                  setNeverShowAlertaResetParam(true);
                }
              }}
              autoFocus
            />
          </div>
        );
      };
    
      const renderHeader = (): React.ReactElement => {
        return <h2 style={{ margin: "0" }}>Alerta</h2>;
      };

    return <>
        <Dialog
            draggable={false}
            visible={showAlertaResetParam && !neverShowAlertaResetParam}
            style={{ width: "50vw" }}
            footer={renderFooter()}
            onHide={() => setShowAlertaResetParam(false)}
            header={renderHeader()}
            headerStyle={{
            padding: "15px 1.5rem",
            }}
            contentStyle={{
            paddingBottom: "0",
            }}
        >
        <p>As configurações da página e os filtros foram resetados.</p>
      </Dialog>
    </>
}