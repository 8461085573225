import { AxiosRequestConfig, AxiosResponse } from "axios";
import UsuarioCadastro from "interfaces/usuario-cadastro";
import TokenService from "./token.service";
import api from "./api";

const getUserInfo = async (): Promise<any> => {
  return api.get("/me").then((response) => {
    if (response.data) {
      TokenService.setUserInfo(response.data);
      if((window as any).heap) {
        (window as any).heap.identify(response.data.email);
        (window as any).heap.addUserProperties({name: response.data.name ?? "", email: response.data.email ?? ""})
      }
    }
    return response.data;
  });
};

const getUsuariosComFiltro = async (
  page: number,
  pageSize: number,
  filtro?: string,
  sort?: any[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("page", page ? page : 0);
  config.params.append("size", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filtro) {
    config.params.append("filter", filtro);
  } else {
    config.params.append("filter", "");
  }
  return api.get(`/account/search`, config);
};

const updateUser = async (user: UsuarioCadastro): Promise<any> => {
  return api.put(`/account/${user.id}`, user);
};

const saveUser = async (user: UsuarioCadastro): Promise<any> => {
  return api.post("/account", user);
};

const getUsuarioEmpresas = async (dominio: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (dominio) {
    config.params.append("domain", dominio);
  }
  return api.get(`account/company-from-email`, config).then(
    (response) => {
      sessionStorage.setItem("empresasUsuario", JSON.stringify(response.data));
      return response;
    }
  );
};

const getUsuarioRelatorioPorEmpresa = async (
  empresaId: number
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (empresaId) {
    config.params.append("companyId", `${empresaId}`);
  }
  return api.get(`/company-and-report/search`, config);
};

const cadastraLoteUsuarios = async (usuarios: any[]): Promise<any> => {
  return api.post("/account/list", usuarios);
};

const gerarNovaSenha = async (usuarioId: string): Promise<any> => {
  return api.post(`/account/updatePwd/${usuarioId}`, {});
};

const enviarEmailBoasVindas = async (usuarioId: string): Promise<any> => {
  return api.post(`/account/welcome/${usuarioId}`);
};

const getUserById = async (userId: number): Promise<AxiosResponse> => {
  return api.get(`/account/${userId}`);
};

const atualizarNotificacoes = async (
  userId: number,
  isEnabled: boolean
): Promise<AxiosResponse> => {
  return api.put(`/account/${userId}/user`, {
    notification: isEnabled,
  });
};

const ativacaoUsuario = async (
  userId: number,
  isActive: boolean
): Promise<AxiosResponse> => {
  return api.put(`/account/${userId}`, { active: isActive });
};

const saveDemoViewed = async (demoName: string): Promise<AxiosResponse> => {
  return api.put(`/account/guided-demo/${demoName}`, {});
}

const UserService = {
  getUserInfo,
  getUsuariosComFiltro,
  updateUser,
  getUsuarioEmpresas,
  getUsuarioRelatorioPorEmpresa,
  saveUser,
  cadastraLoteUsuarios,
  gerarNovaSenha,
  enviarEmailBoasVindas,
  getUserById,
  atualizarNotificacoes,
  ativacaoUsuario,
  saveDemoViewed
};

export default UserService;
