import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useShowCleanerAlertStore = create(
  persist(
    (set) => ({
      show: false,
      setShow: (show: boolean) => set((state: any) => ({ show: show })),
    }),
    {
      name: "showCleanerAlert", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useShowCleanerAlertStore;
