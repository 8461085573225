import { create, StateCreator } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type PageInfo = {
  pageInfo: any;
  setPageInfo: (pageInfo: any) => void;
};

const usePageInfoStore = create<PageInfo>((set) => ({
  pageInfo: null,
  setPageInfo: (pageInfo: any) => set((state: any) => ({ pageInfo: pageInfo })),
  reset: () => {
    set({
      pageInfo: null,
    });
  },
}));

export default usePageInfoStore;
