/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import html2canvas from "html2canvas";
import interact from "interactjs";
import { library, icon } from "@fortawesome/fontawesome-svg-core";
import {
  faCopy,
  faSave,
  faTimesCircle,
  faPencilAlt,
  faFont,
  faUndo,
  faRedo,
  faArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { fabric } from "fabric";

library.add(
  faCopy,
  faSave,
  faTimesCircle,
  faPencilAlt,
  faFont,
  faUndo,
  faRedo,
  faArrowsAlt
);

const addZero = (number) => {
  return number <= 9 ? "0" + number : number;
};

const today = new Date();
const date =
  addZero(today.getDate().toString()) +
  "/" +
  addZero((today.getMonth() + 1).toString()) +
  "/" +
  today.getFullYear();

const copiedToClipboardEvent = new Event("copiedToClipboard");
const saveLocallyEvent = new Event("saveLocally");

var isVarejoGlobal;

var drawable = false;

var textMode = false;

var isOpenedVar = false;

var fabricCanvas;

var fabricCanvasContainer;

var textBox;

var canvasTypeHistory = [];

var canvasTypeHistoryStep = -1;

var initCounter = 0;

var sandboxshotCanvas;
var sandboxshotCtx;

const isOpened = () => {
  return isOpenedVar;
};

const historyInit = () => {
  /**
   * Override the initialize function for the _historyInit();
   */
  fabric.Canvas.prototype.initialize = (function (originalFn) {
    return function (...args) {
      originalFn.call(this, ...args);
      this._historyInit();
      return this;
    };
  })(fabric.Canvas.prototype.initialize);

  /**
   * Override the dispose function for the _historyDispose();
   */
  fabric.Canvas.prototype.dispose = (function (originalFn) {
    return function (...args) {
      originalFn.call(this, ...args);
      this._historyDispose();
      return this;
    };
  })(fabric.Canvas.prototype.dispose);

  /**
   * Returns current state of the string of the canvas
   */
  fabric.Canvas.prototype._historyNext = function () {
    return JSON.stringify(this.toDatalessJSON(this.extraProps));
  };

  /**
   * Returns an object with fabricjs event mappings
   */
  fabric.Canvas.prototype._historyEvents = function () {
    return {
      "object:added": this._historySaveAction,
      "object:removed": this._historySaveAction,
      "object:modified": this._historySaveAction,
      "object:skewing": this._historySaveAction,
    };
  };

  /**
   * Initialization of the plugin
   */
  fabric.Canvas.prototype._historyInit = function () {
    this.historyUndo = [];
    this.historyRedo = [];
    this.extraProps = ["selectable"];
    this.historyNextState = this._historyNext();

    this.on(this._historyEvents());
  };

  /**
   * Remove the custom event listeners
   */
  fabric.Canvas.prototype._historyDispose = function () {
    this.off(this._historyEvents());
  };

  /**
   * It pushes the state of the canvas into history stack
   */
  fabric.Canvas.prototype._historySaveAction = function () {
    if (this.historyProcessing) return;

    const json = this.historyNextState;
    this.historyUndo.push(json);
    this.historyNextState = this._historyNext();
    this.fire("history:append", { json: json });
  };

  /**
   * Undo to latest history.
   * Pop the latest state of the history. Re-render.
   * Also, pushes into redo history.
   */
  fabric.Canvas.prototype.undo = function (callback) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;

    const history = this.historyUndo.pop();
    if (history) {
      // Push the current state to the redo history
      this.historyRedo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, "history:undo", callback);
    } else {
      this.historyProcessing = false;
    }
  };

  /**
   * Redo to latest undo history.
   */
  fabric.Canvas.prototype.redo = function (callback) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;
    const history = this.historyRedo.pop();
    if (history) {
      // Every redo action is actually a new action to the undo history
      this.historyUndo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, "history:redo", callback);
    } else {
      this.historyProcessing = false;
    }
  };

  fabric.Canvas.prototype._loadHistory = function (history, event, callback) {
    var that = this;

    this.loadFromJSON(history, function () {
      that.renderAll();
      that.fire(event);
      that.historyProcessing = false;

      if (callback && typeof callback === "function") callback();
    });
  };

  /**
   * Clear undo and redo history stacks
   */
  fabric.Canvas.prototype.clearHistory = function () {
    this.historyUndo = [];
    this.historyRedo = [];
    this.fire("history:clear");
  };

  /**
   * Off the history
   */
  fabric.Canvas.prototype.offHistory = function () {
    this.historyProcessing = true;
  };

  /**
   * On the history
   */
  fabric.Canvas.prototype.onHistory = function () {
    this.historyProcessing = false;

    this._historySaveAction();
  };
};

const turnEverythingToSand = () => {
  const sandboxshot = document.getElementsByClassName("sandboxshot")[0];
  while (sandboxshot.firstChild) {
    sandboxshot.removeChild(sandboxshot.lastChild);
  }
  drawable = false;
  textMode = false;
  for (
    let i = 0;
    i < document.getElementsByClassName("fabric-canvas").length;
    i++
  ) {
    document.getElementsByClassName("fabric-canvas")[i].remove();
  }
  isOpenedVar = false;
  canvasTypeHistoryStep = -1;
  canvasTypeHistory = [];
  initCounter = 1;
};

const draw = () => {
  textMode = false;
  drawable = !drawable;

  if (document.getElementById("fabric-canvas")) {
    if (!drawable) {
      document.getElementsByClassName("DRAW")[0].style.background = "#f29200";
      document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
      document.getElementsByClassName("DRAW")[0].style.border =
        "1px solid #f29200";
      document.getElementsByClassName("TEXT")[0].style.border =
        "1px solid #f29200";
      fabricCanvas.isDrawingMode = false;
    } else {
      document.getElementsByClassName("DRAW")[0].style.background = "#0075b3";
      document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
      document.getElementsByClassName("DRAW")[0].style.border =
        "1px solid #0075b3";
      document.getElementsByClassName("TEXT")[0].style.border =
        "1px solid #f29200";
      fabricCanvas.isDrawingMode = true;
      fabricCanvas.freeDrawingBrush.width = 2;
      fabricCanvas.freeDrawingBrush.color = "#ff0000";
    }
    return;
  }

  document.getElementsByClassName("DRAW")[0].style.background = "#0075b3";
  document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
  document.getElementsByClassName("DRAW")[0].style.border = "1px solid #0075b3";
  document.getElementsByClassName("TEXT")[0].style.border = "1px solid #f29200";

  const sandboxshotDiv = document.getElementsByClassName("sandboxshotArea")[0];

  const fCanvas = document.createElement("canvas");
  sandboxshotDiv.appendChild(fCanvas);
  fCanvas.id = "fabric-canvas";

  fabricCanvas = new fabric.Canvas("fabric-canvas", {
    containerClass: "fabric-canvas-container",
    width: sandboxshotDiv.clientWidth,
    height: sandboxshotDiv.clientHeight,
    freeDrawingCursor:
      'url("https://storage.googleapis.com/v360-platform-assets/sandboxshot/draw-cursor.png") 7 22,crosshair',
    defaultCursor: "move",
  });

  fabricCanvasContainer = document.getElementsByClassName(
    "fabric-canvas-container"
  )[0];
  fabricCanvasContainer.style.zIndex = "9999";
  fabricCanvasContainer.style.position = "absolute";

  fabricCanvas.isDrawingMode = true;
  fabricCanvas.freeDrawingBrush.width = 2;
  fabricCanvas.freeDrawingBrush.color = "#ff0000";

  fabricCanvas.on("object:modified", function () {
    textMode = false;
    drawable = false;
    canvasTypeHistoryStep++;
    if (canvasTypeHistoryStep < canvasTypeHistory.length) {
      canvasTypeHistory.length = canvasTypeHistoryStep;
    }
    canvasTypeHistory.push("object");
  });

  fabricCanvas.on("object:rotating", function () {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("object:scaling", function () {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("object:moving", function () {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("object:skewing", function () {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("selection:created", function (ev) {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("selection:updated", function (ev) {
    textMode = true;
    drawable = true;
  });

  fabricCanvas.on("path:created", function () {
    canvasTypeHistoryStep++;
    if (canvasTypeHistoryStep < canvasTypeHistory.length) {
      canvasTypeHistory.length = canvasTypeHistoryStep;
    }
    canvasTypeHistory.push("object");
  });
};

const undo = () => {
  if (canvasTypeHistoryStep >= 0) {
    fabricCanvas.undo();
    canvasTypeHistoryStep--;
  }
};

const redo = () => {
  if (canvasTypeHistoryStep < canvasTypeHistory.length - 1) {
    canvasTypeHistoryStep++;

    fabricCanvas.redo();
  }
};

const insertTextHandler = () => {
  var listener = (event) => {
    if (event.target.nodeName == "CANVAS") {
      insertText(event);
      textMode = false;
    }
    sandboxshotDiv.removeEventListener("mousedown", listener, false);
    document.getElementsByClassName("DRAW")[0].style.background = "#f29200";
    document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
    document.getElementsByClassName("DRAW")[0].style.border =
      "1px solid #f29200";
    document.getElementsByClassName("TEXT")[0].style.border =
      "1px solid #f29200";
    fabricCanvas.defaultCursor = "move";
  };
  const sandboxshotDiv = document.getElementsByClassName("sandboxshotArea")[0];
  drawable = false;
  textMode = !textMode;
  if (fabricCanvas != undefined) {
    fabricCanvas.isDrawingMode = false;
    if (textMode == true) {
      fabricCanvas.defaultCursor =
        'url("https://storage.googleapis.com/v360-platform-assets/sandboxshot/text-cursor.png") 7 22,crosshair';
    } else {
      fabricCanvas.defaultCursor = "move";
    }
  }

  if (textMode == true) {
    document.getElementsByClassName("DRAW")[0].style.background = "#f29200";
    document.getElementsByClassName("TEXT")[0].style.background = "#0075b3";
    document.getElementsByClassName("DRAW")[0].style.border =
      "1px solid #f29200";
    document.getElementsByClassName("TEXT")[0].style.border =
      "1px solid #0075b3";
    sandboxshotDiv.addEventListener("mousedown", listener, false);
  }

  if (textMode == false) {
    document.getElementsByClassName("DRAW")[0].style.background = "#f29200";
    document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
    document.getElementsByClassName("DRAW")[0].style.border =
      "1px solid #f29200";
    document.getElementsByClassName("TEXT")[0].style.border =
      "1px solid #f29200";
  }

  if (!document.getElementById("fabric-canvas")) {
    const fCanvas = document.createElement("canvas");
    sandboxshotDiv.appendChild(fCanvas);
    fCanvas.id = "fabric-canvas";

    fabricCanvas = new fabric.Canvas("fabric-canvas", {
      containerClass: "fabric-canvas-container",
      width: sandboxshotDiv.clientWidth,
      height: sandboxshotDiv.clientHeight,
      defaultCursor:
        'url("https://storage.googleapis.com/v360-platform-assets/sandboxshot/text-cursor.png") 7 22,crosshair',
      freeDrawingCursor:
        'url("https://storage.googleapis.com/v360-platform-assets/sandboxshot/draw-cursor.png") 7 22,crosshair',
    });

    fabricCanvasContainer = document.getElementsByClassName(
      "fabric-canvas-container"
    )[0];
    fabricCanvasContainer.style.zIndex = "9999";
    fabricCanvasContainer.style.position = "absolute";

    fabricCanvas.on("object:modified", function () {
      textMode = false;
      drawable = false;
      canvasTypeHistoryStep++;
      if (canvasTypeHistoryStep < canvasTypeHistory.length) {
        canvasTypeHistory.length = canvasTypeHistoryStep;
      }
      canvasTypeHistory.push("object");
    });

    fabricCanvas.on("object:rotating", function () {
      textMode = true;
      drawable = true;
    });

    fabricCanvas.on("object:scaling", function () {
      textMode = true;
      drawable = true;
    });

    fabricCanvas.on("object:moving", function () {
      textMode = true;
      drawable = true;
    });

    fabricCanvas.on("object:skewing", function () {
      textMode = true;
      drawable = true;
    });

    fabricCanvas.on("selection:created", function (ev) {
      textMode = true;
      drawable = true;
    });

    fabricCanvas.on("path:created", function () {
      canvasTypeHistoryStep++;
      if (canvasTypeHistoryStep < canvasTypeHistory.length) {
        canvasTypeHistory.length = canvasTypeHistoryStep;
      }
      canvasTypeHistory.push("object");
    });
  }
};

const insertText = (mouseEvent) => {
  if (document.getElementById("fabric-canvas")) {
    textBox = new fabric.Textbox("Clique para editar", {
      width: 150,
      top: mouseEvent.offsetY,
      left: mouseEvent.offsetX,
      fontSize: 16,
      textAlign: "center",
      fixedWidth: 150,
      fill: "red",
    });

    fabricCanvas.add(textBox);

    canvasTypeHistoryStep++;
    if (canvasTypeHistoryStep < canvasTypeHistory.length) {
      canvasTypeHistory.length = canvasTypeHistoryStep;
    }
    canvasTypeHistory.push("object");

    return;
  }

  textBox = new fabric.Textbox("Clique para editar", {
    width: 150,
    top: mouseEvent.offsetY,
    left: mouseEvent.offsetX,
    fontSize: 16,
    textAlign: "center",
    fixedWidth: 150,
    fill: "red",
  });

  fabricCanvas.add(textBox);

  canvasTypeHistoryStep++;
  if (canvasTypeHistoryStep < canvasTypeHistory.length) {
    canvasTypeHistory.length = canvasTypeHistoryStep;
  }
  canvasTypeHistory.push("object");
};

const fillActionsDivWithButtons = (parentDiv) => {
  const ACTIONS = {
    TEXT: {
      function: insertTextHandler,
      tooltip: "Inserir campo de texto",
      icon: "font",
    },
    DRAW: {
      function: draw,
      tooltip: "Desenhar com uma caneta",
      icon: "pencil-alt",
    },
    UNDO: {
      function: undo,
      tooltip: "Desfazer última ação (Ctrl + Z)",
      icon: "undo",
    },
    REDO: {
      function: redo,
      tooltip: "Refazer última ação (Ctrl + Y)",
      icon: "redo",
    },
    MOVE: {
      function: move,
      tooltip: "Mover",
      icon: "arrows-alt",
    },
    SAVE_LOCAL: {
      function: takeScreenshotLocal,
      tooltip: "Fazer download (Ctrl + S)",
      icon: "save",
    },
    SAVE_CLIPBOARD: {
      function: takeScreenshotClipboard,
      tooltip: "Copiar seleção (Ctrl + C)",
      icon: "copy",
    },
    CLOSE: {
      function: turnEverythingToSand,
      tooltip: "Sair (Esc)",
      icon: "times-circle",
    },
  };

  Object.keys(ACTIONS).forEach((action) => {
    const button = document.createElement("div");
    button.setAttribute("title", ACTIONS[action].tooltip);
    button.classList.add(action);
    Object.assign(button.style, {
      width: "40px",
      height: "40px",
      border: "1px solid #f29200",
      color: "#FFF",
      background: "#f29200",
      borderRadius: "50%",
      zIndex: "9998",
      cursor: "pointer",
      margin: "0 10px 10px 0",
      filter: "drop-shadow(0 0 0.2rem white)",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontSize: "larger",
    });
    const iconContainer = document.createElement("span");
    iconContainer.innerHTML = icon({
      prefix: "fas",
      iconName: `${ACTIONS[action].icon}`,
    }).html;
    button.appendChild(iconContainer);
    button.onclick = ACTIONS[action].function;
    parentDiv.appendChild(button);
  });
};

const move = () => {
  textMode = false;
  drawable = false;
  document.getElementsByClassName("DRAW")[0].style.background = "#f29200";
  document.getElementsByClassName("TEXT")[0].style.background = "#f29200";
  document.getElementsByClassName("DRAW")[0].style.border = "1px solid #f29200";
  document.getElementsByClassName("TEXT")[0].style.border = "1px solid #f29200";

  if (fabricCanvas != undefined) {
    fabricCanvas.isDrawingMode = false;
  }
};

const takeScreenshotLocal = () => {
  const today = new Date();
  const date =
    addZero(today.getDate().toString()) +
    "/" +
    addZero((today.getMonth() + 1).toString()) +
    "/" +
    today.getFullYear();
  const dateFilename =
    addZero(today.getDate().toString()) +
    "-" +
    addZero((today.getMonth() + 1).toString()) +
    "-" +
    today.getFullYear();
  const timeFilename =
    addZero(today.getHours().toString()) +
    "-" +
    addZero(today.getMinutes().toString()) +
    "-" +
    addZero(today.getSeconds().toString());
  try {
    const sandboxshotDiv = document.getElementsByClassName("sandboxshotArea")[0];
    const rect = sandboxshotDiv.getBoundingClientRect();
    closeActions();

  html2canvas(document.body, {
    x: rect.x + 8,
    y: rect.y + 8,
    width: rect.width - 10,
    height: rect.height - 10,
  })
    .then(function (canvas) {
      document.body.appendChild(canvas);
      return canvas;
    })
    .then((canvas) => {
      let filename = "sandboxshot.png";
      const nomePagina = document.getElementById("page--name")?.textContent;
      if (nomePagina) {
        filename = `${nomePagina ?? "screenshot"} - ${dateFilename}_${timeFilename}.png`;
      }
      if (isVarejoGlobal) {
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(
          rect.x + rect.width - 215,
          `${rect.y + rect.height - 30}`,
          400,
          150
        );
        ctx.textBaseline = "hanging";
        ctx.font = "bold 16px sans-serif";
        ctx.fillStyle = "black";
        ctx.fillText(
          `Varejo 360 em ${date}`,
          `${rect.x + rect.width - 200}`,
          `${rect.y + rect.height - 20}`
        );
      }
      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const a = document.createElement("a");
      a.setAttribute("download", filename);
      a.setAttribute("href", image);
      a.click();
      canvas.remove();
      turnEverythingToSand();
      document.dispatchEvent(saveLocallyEvent);
    });
  } catch (error) {
    console.error(error);
  }
};

const takeScreenshotClipboard = () => {
  const today = new Date();
  const date =
    addZero(today.getDate().toString()) +
    "/" +
    addZero((today.getMonth() + 1).toString()) +
    "/" +
    today.getFullYear();

  const sandboxshotDiv = document.getElementsByClassName("sandboxshotArea")[0];
  const rect = sandboxshotDiv.getBoundingClientRect();
  closeActions();

  html2canvas(document.body, {
    x: rect.x,
    y: rect.y,
    width: rect.width,
    height: rect.height,
  })
    .then(function (canvas) {
      document.body.appendChild(canvas);
      return canvas;
    })
    .then((canvas) => {
      if (isVarejoGlobal) {
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(
          rect.x + rect.width - 215,
          `${rect.y + rect.height - 30}`,
          400,
          150
        );
        ctx.textBaseline = "hanging";
        ctx.font = "bold 16px sans-serif";
        ctx.fillStyle = "black";
        ctx.fillText(
          `Varejo 360 em ${date}`,
          `${rect.x + rect.width - 200}`,
          `${rect.y + rect.height - 20}`
        );
      }
      try {
        const p1 = new Promise((resolve, reject) => {
          canvas.toBlob((blob) =>
            navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]).catch((err) => console.error(err))
          );
          resolve();
        });
        p1.then(turnEverythingToSand()).then(
          document.dispatchEvent(copiedToClipboardEvent)
        ).catch((err) => {
          console.error(err);
        });
      } catch (error) {
        console.error(error);
      }
    });
};
const closeActions = () => {
  const actionsDivPrev = document.getElementsByClassName("actionsDiv")[0];
  if (actionsDivPrev) {
    actionsDivPrev.parentElement.removeChild(actionsDivPrev);
  }
};
const openActions = (
  parentDiv,
  revert = false,
  hasVerticalSpace = true,
  fullScreen = false
) => {
  const actionsDivPrev = document.getElementsByClassName("actionsDiv")[0];
  if (actionsDivPrev) {
    actionsDivPrev.parentElement.removeChild(actionsDivPrev);
  }
  const actionsDiv = document.createElement("div");
  const parentDivMeasures = parentDiv.getBoundingClientRect();
  actionsDiv.classList.add("actionsDiv");
  let marginTop = "";
  let marginLeft = "";
  if (fullScreen) {
    Object.assign(actionsDiv.style, {
      width: `${parentDivMeasures.width}`,
      position: "absolute",
      zIndex: "9997",
      marginTop: "25px",
      marginLeft: `${parentDivMeasures.width - 360}px`,
      cursor: "default",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    });
  } else {
    if (hasVerticalSpace) {
      if (!revert) {
        marginTop =
          parentDivMeasures.height <= 0
            ? "25px"
            : `${parentDivMeasures.height + 5}px`;
      } else {
        marginTop = "-55px";
      }
      Object.assign(actionsDiv.style, {
        width: `${parentDivMeasures.width}`,
        position: "absolute",
        zIndex: "9997",
        marginTop: marginTop,
        cursor: "default",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      });
    } else {
      if (revert) {
        marginLeft =
          parentDivMeasures.width <= 0
            ? "25px"
            : `${parentDivMeasures.width + 5}px`;
      } else {
        marginLeft = "-55px";
      }
      Object.assign(actionsDiv.style, {
        width: `${parentDivMeasures.width}`,
        position: "absolute",
        zIndex: "9997",
        marginLeft: marginLeft,
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      });
    }
  }

  parentDiv.appendChild(actionsDiv);
  fillActionsDivWithButtons(actionsDiv);
};

const addCirclesToBorder = (parentDiv) => {
  const NO = document.createElement("div");
  const N = document.createElement("div");
  const NE = document.createElement("div");
  const L = document.createElement("div");
  const SE = document.createElement("div");
  const S = document.createElement("div");
  const SO = document.createElement("div");
  const O = document.createElement("div");

  Object.assign(NO.style, {
    position: "absolute",
    left: "-7px",
    top: "-7px",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(N.style, {
    position: "absolute",
    left: "50%",
    top: "-7px",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(NE.style, {
    position: "absolute",
    left: "100%",
    top: "-7px",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(L.style, {
    position: "absolute",
    left: "100%",
    top: "50%",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(SE.style, {
    position: "absolute",
    left: "100%",
    top: "99.5%",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(S.style, {
    position: "absolute",
    left: "50%",
    top: "100%",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(SO.style, {
    position: "absolute",
    left: "-7px",
    top: "99.5%",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });
  Object.assign(O.style, {
    position: "absolute",
    left: "-7px",
    top: "50%",
    width: "10px",
    height: "10px",
    shapeOutside: "circle()",
    clipPath: "circle()",
    background: "#f29200",
  });

  parentDiv.appendChild(NO);
  parentDiv.appendChild(N);
  parentDiv.appendChild(NE);
  parentDiv.appendChild(L);
  parentDiv.appendChild(SE);
  parentDiv.appendChild(S);
  parentDiv.appendChild(SO);
  parentDiv.appendChild(O);
};

const openBox = (isVarejo = true, width = document.body.getBoundingClientRect().width, height = document.body.getBoundingClientRect().height) => {
  isVarejoGlobal = isVarejo;
  const sandboxshotDiv = document.getElementsByClassName("sandboxshot")[0];
  const sandboxshotArea = document.createElement("div");
  sandboxshotArea.classList.add("sandboxshotArea");
  Object.assign(sandboxshotArea.style, {
    width: `${width - width * 0.15}px`,
    height: `${height - height * 0.15 - 100}px`,
    outline: "solid #f29200",
    position: "absolute",
    zIndex: "9997",
    touchAction: "none",
    boxSizing: "border-box",
    left: `${(width * 0.075)}px`,
    top: `${(height * 0.075) + 30}px`,
  });
  sandboxshotDiv.appendChild(sandboxshotArea);

  addCirclesToBorder(sandboxshotArea);

  sandboxshotCanvas = document.createElement("canvas");
  const watermarkCanvas = document.createElement("canvas");

  sandboxshotDiv.appendChild(sandboxshotCanvas);
  sandboxshotCanvas.style.opacity = "0.7";
  sandboxshotCanvas.style.position = "absolute";
  sandboxshotCanvas.style.zIndex = "9996";

  sandboxshotDiv.appendChild(watermarkCanvas);
  watermarkCanvas.style.opacity = "1";
  watermarkCanvas.style.position = "absolute";
  watermarkCanvas.style.zIndex = "9996";

  let sandboxshotAreaMeasures = sandboxshotArea.getBoundingClientRect();

  sandboxshotCtx = sandboxshotCanvas.getContext("2d");
  sandboxshotCtx.canvas.width = window.innerWidth;
  sandboxshotCtx.canvas.height = window.innerHeight;

  const watermarkCtx = watermarkCanvas.getContext("2d");
  watermarkCtx.canvas.width = window.innerWidth;
  watermarkCtx.canvas.height = window.innerHeight;

  sandboxshotCtx.globalCompositeOperation = "xor";

  sandboxshotCtx.fillRect(0, 0, document.body.getBoundingClientRect().width, document.body.getBoundingClientRect().height);
  sandboxshotCtx.fillRect(
    sandboxshotAreaMeasures.x,
    sandboxshotAreaMeasures.y,
    sandboxshotAreaMeasures.width,
    sandboxshotAreaMeasures.height
  );

  openActions(sandboxshotArea);

  if (initCounter == 0) {
    historyInit();
  }

  isOpenedVar = true;

  if (isVarejo) {
    watermarkCtx.fillStyle = "white";
    watermarkCtx.fillRect(
      sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 215,
      sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 30,
      215,
      30
    );
    watermarkCtx.fillStyle = "black";
    watermarkCtx.textBaseline = "hanging";
    watermarkCtx.font = "bold 16px sans-serif";
    watermarkCtx.fillText(
      `Varejo 360 em ${date}`,
      `${sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 200}`,
      `${sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 20}`
    );
  }

  document.body.addEventListener("keydown", function (ev) {
    if (ev.code === "Escape") {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      turnEverythingToSand();
    }

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : ev.keyCode === 17 ? true : false;

    if (ev.keyCode == 83 && ctrl) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      //console.log("Ctrl+S is pressed.");
      takeScreenshotLocal();
    } else if (ev.keyCode == 67 && ctrl) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      //console.log("Ctrl+C is pressed.");
      takeScreenshotClipboard();
    } else if (ev.keyCode == 90 && ctrl) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      //console.log("Ctrl+Z is pressed.");
      undo();
    } else if (ev.keyCode == 89 && ctrl) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      //console.log("Ctrl+Y is pressed.");
      redo();
    }
  });

  const position = { x: 0, y: 0 };

  const initialBounderies = {
    left: -sandboxshotAreaMeasures.x,
    right: sandboxshotAreaMeasures.left,
    top: -sandboxshotAreaMeasures.y,
    bottom: sandboxshotAreaMeasures.top,
  };

  interact(".sandboxshotArea")
    .resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          if (!drawable && !textMode) {
            position.x = (position.x || 0) + event.deltaRect.left;
            position.y = (position.y || 0) + event.deltaRect.top;

            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${position.x}px, ${position.y}px)`,
            });

            sandboxshotCtx.clearRect(
              0,
              0,
              sandboxshotCtx.canvas.width,
              sandboxshotCtx.canvas.height
            );
            watermarkCtx.clearRect(
              0,
              0,
              watermarkCtx.canvas.width,
              watermarkCtx.canvas.height
            );

            sandboxshotAreaMeasures = sandboxshotArea.getBoundingClientRect();

            fabricCanvasContainer = document.getElementsByClassName(
              "fabric-canvas-container"
            )[0];

            if (
              fabricCanvas !== undefined &&
              fabricCanvasContainer !== undefined
            ) {
              fabricCanvas.setWidth(event.rect.width);
              fabricCanvas.setHeight(event.rect.height);

              Object.assign(fabricCanvasContainer.style, {
                width: `${event.rect.width}px`,
                height: `${event.rect.height}px`,
              });
            }

            const screenMeasures = document.body.getBoundingClientRect();

            const isActionDivTouchingBottom =
              sandboxshotAreaMeasures.bottom >= screenMeasures.bottom - 50;
            const isActionDivTouchingTop = sandboxshotAreaMeasures.top <= 50;
            const isActionDivTouchingLeft = sandboxshotAreaMeasures.left <= 50;
            const isActionDivTouchingRight =
              sandboxshotAreaMeasures.right >= screenMeasures.right - 50;

            sandboxshotCtx.fillRect(
              0,
              0,
              screenMeasures.width,
              screenMeasures.height
            );
            sandboxshotCtx.fillRect(
              sandboxshotAreaMeasures.x,
              sandboxshotAreaMeasures.y,
              sandboxshotAreaMeasures.width,
              sandboxshotAreaMeasures.height
            );

            if (isVarejo) {
              watermarkCtx.fillStyle = "white";
              watermarkCtx.fillRect(
                sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 215,
                sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 30,
                215,
                30
              );
              watermarkCtx.fillStyle = "black";
              watermarkCtx.textBaseline = "hanging";
              watermarkCtx.font = "bold 16px sans-serif";
              watermarkCtx.fillText(
                `Varejo 360 em ${date}`,
                `${
                  sandboxshotAreaMeasures.x +
                  sandboxshotAreaMeasures.width -
                  200
                }`,
                `${
                  sandboxshotAreaMeasures.y +
                  sandboxshotAreaMeasures.height -
                  20
                }`
              );
            }
            if (isActionDivTouchingBottom) {
              if (isActionDivTouchingTop) {
                if (isActionDivTouchingLeft) {
                  if (isActionDivTouchingRight) {
                    openActions(sandboxshotArea, true, true, true);
                  } else {
                    openActions(sandboxshotArea, true, false);
                  }
                } else {
                  openActions(sandboxshotArea, false, false);
                }
              } else {
                openActions(sandboxshotArea, true, true);
              }
            } else {
              openActions(sandboxshotArea, false, true);
            }
          }
        },
      },
    })
    .draggable({
      listeners: {
        move: (event) => {
          if (!drawable && !textMode) {
            const isActionDivTouchingBottom =
              sandboxshotAreaMeasures.bottom >= document.body.getBoundingClientRect().bottom - 50;
            const isActionDivTouchingTop = sandboxshotAreaMeasures.top <= 50;
            const isActionDivTouchingLeft = sandboxshotAreaMeasures.left <= 50;
            const isActionDivTouchingRight =
              sandboxshotAreaMeasures.right >= document.body.getBoundingClientRect().right - 50;

            const isSandboxshotAreaTouchingTop =
              sandboxshotAreaMeasures.top <= 0;
            const isSandboxshotAreaTouchingBottom =
              sandboxshotAreaMeasures.bottom >= document.body.getBoundingClientRect().height;
            const isSandboxshotAreaTouchingLeft =
              sandboxshotAreaMeasures.left <= 0;
            const isSandboxshotAreaTouchingRight =
              sandboxshotAreaMeasures.right >= document.body.getBoundingClientRect().width;

            if (
              !isSandboxshotAreaTouchingLeft &&
              !isSandboxshotAreaTouchingRight
            ) {
              position.x += event.dx;
            }

            if (
              !isSandboxshotAreaTouchingTop &&
              !isSandboxshotAreaTouchingBottom
            ) {
              position.y += event.dy;
            }

            if (isSandboxshotAreaTouchingLeft && event.dx > 0) {
              position.x += event.dx;
            }
            if (isSandboxshotAreaTouchingRight && event.dx <= 0) {
              position.x += event.dx;
            }
            if (isSandboxshotAreaTouchingTop && event.dy > 0) {
              position.y += event.dy;
            }
            if (isSandboxshotAreaTouchingBottom && event.dy <= 0) {
              position.y += event.dy;
            }

            sandboxshotCtx.clearRect(
              0,
              0,
              sandboxshotCtx.canvas.width,
              sandboxshotCtx.canvas.height
            );
            watermarkCtx.clearRect(
              0,
              0,
              watermarkCtx.canvas.width,
              watermarkCtx.canvas.height
            );

            sandboxshotAreaMeasures = sandboxshotArea.getBoundingClientRect();

            sandboxshotCtx.fillRect(
              0,
              0,
              document.body.getBoundingClientRect().width,
              document.body.getBoundingClientRect().height
            );
            sandboxshotCtx.fillRect(
              sandboxshotAreaMeasures.x,
              sandboxshotAreaMeasures.y,
              sandboxshotAreaMeasures.width,
              sandboxshotAreaMeasures.height
            );

            if (isVarejo) {
              watermarkCtx.fillStyle = "white";
              watermarkCtx.fillRect(
                sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 215,
                sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 30,
                215,
                30
              );
              watermarkCtx.fillStyle = "black";
              watermarkCtx.textBaseline = "hanging";
              watermarkCtx.font = "bold 16px sans-serif";
              watermarkCtx.fillText(
                `Varejo 360 em ${date}`,
                `${
                  sandboxshotAreaMeasures.x +
                  sandboxshotAreaMeasures.width -
                  200
                }`,
                `${
                  sandboxshotAreaMeasures.y +
                  sandboxshotAreaMeasures.height -
                  20
                }`
              );
            }

            event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;

            if (isActionDivTouchingBottom) {
              if (isActionDivTouchingTop) {
                if (isActionDivTouchingLeft) {
                  if (isActionDivTouchingRight) {
                    openActions(sandboxshotArea, true, true, true);
                  } else {
                    openActions(sandboxshotArea, true, false);
                  }
                } else {
                  openActions(sandboxshotArea, false, false);
                }
              } else {
                openActions(sandboxshotArea, true, true);
              }
            } else {
              openActions(sandboxshotArea, false, true);
            }
          }
        },
        end(event) {
          sandboxshotCtx.clearRect(
            0,
            0,
            sandboxshotCtx.canvas.width,
            sandboxshotCtx.canvas.height
          );
          watermarkCtx.clearRect(
            0,
            0,
            watermarkCtx.canvas.width,
            watermarkCtx.canvas.height
          );

          sandboxshotAreaMeasures = sandboxshotArea.getBoundingClientRect();

          sandboxshotCtx.fillRect(
            0,
            0,
            document.body.getBoundingClientRect().width,
            document.body.getBoundingClientRect().height
          );
          sandboxshotCtx.fillRect(
            sandboxshotAreaMeasures.x,
            sandboxshotAreaMeasures.y,
            sandboxshotAreaMeasures.width,
            sandboxshotAreaMeasures.height
          );

          if (isVarejo) {
            watermarkCtx.fillStyle = "white";
            watermarkCtx.fillRect(
              sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 215,
              sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 30,
              215,
              30
            );
            watermarkCtx.fillStyle = "black";
            watermarkCtx.textBaseline = "hanging";
            watermarkCtx.font = "bold 16px sans-serif";
            watermarkCtx.fillText(
              `Varejo 360 em ${date}`,
              `${
                sandboxshotAreaMeasures.x + sandboxshotAreaMeasures.width - 200
              }`,
              `${
                sandboxshotAreaMeasures.y + sandboxshotAreaMeasures.height - 20
              }`
            );
          }
        },
      },
    });
};

const sandboxshot = {
  openBox: openBox,
  isOpened: isOpened,
};

export default sandboxshot;
