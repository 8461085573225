import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Categoria from "interfaces/categoria";
import api from "./api";

const getCategoriasFilteredOrNot = (filter?: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (filter) {
    config.params.append("filter", filter);
  }
  return api.get(`/category/search`, config).then((response) => {
    return response.data;
  });
};

const salvarCategoria = (categoria: Categoria): Promise<any> => {
  return api.post("/category", categoria);
};

const atualizarCategoria = async (categoria: Categoria): Promise<any> => {
  return api.put(`/category/${categoria.id}`, categoria);
};

const removerCategoria = async (categoriaId: number): Promise<void> => {
  await api.delete(`/category/${categoriaId}`);
};

const getSectors = async (): Promise<any> => {
  return api.get("/category/sectors");
};

const saveImage = async (file: any): Promise<any> => {
  return api.post(
    "/images",
    { img: file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const deleteImage = async (image: string): Promise<any> => {
  return api.delete(`/images/${image}`);
};

const getCategoriasPainel = async (): Promise<AxiosResponse> => {
  return api.get("/category/allByUser");
};

const getAllCategorias = async (
  page: number,
  pageSize: number,
  filter = "",
  sort?: any[]
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);

  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }
  return api.get("/category/search", config).then((response) => {
    return response.data;
  });
};

const getCategoriasByUserId = (id: number): Promise<AxiosResponse> => {
  return api.get(`/category/allUserCategoryName/${id}`);
};

const getCategoriasByCompanyId = (id: number): Promise<AxiosResponse> => {
  return api.get(`/category/allCompanyCategoryName/${id}`);
};

const getCategoriasByFilterId = (id: number): Promise<AxiosResponse> => {
  return api.get(`/category/allCategoryNameByFilter/${id}`);
};

const getCategoriasByIdAllowedCompany = (
  id: number
): Promise<AxiosResponse> => {
  return api.get(`/category/allCompanyAllow/${id}`);
};

const getAllCustom = async (): Promise<AxiosResponse> => {
  return api.get(`/category/all`);
};

const updateFiltroCategorias = async (
  categoryId: number,
  filterId: number
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  if (categoryId) {
    config.params.append("categoryId", categoryId);
  }
  if (filterId) {
    config.params.append("filterId", filterId);
  }
  return api.put(`/category/updateFilter`, {}, config);
};

const CategoriasService = {
  getCategoriasFilteredOrNot,
  atualizarCategoria,
  salvarCategoria,
  removerCategoria,
  getSectors,
  saveImage,
  deleteImage,
  getCategoriasPainel,
  getAllCategorias,
  getCategoriasByIdAllowedCompany,
  getCategoriasByCompanyId,
  getCategoriasByUserId,
  getCategoriasByFilterId,
  getAllCustom,
  updateFiltroCategorias,
};

export default CategoriasService;
