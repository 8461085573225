const ascSort = (a: any, b: any, code: string): number => {
  if (a[code] < b[code]) {
    return -1;
  }
  if (a[code] > b[code]) {
    return 1;
  }
  return 0;
};

const ascSortLocalCompare = (a: any, b: any, code: string): number => {
  const nameA = a[code].toUpperCase();
  const nameB = b[code].toUpperCase();
  return nameA.localeCompare(nameB);
};

const descSort = (a: any, b: any, code: string): number => {
  if (a[code] < b[code]) {
    return 1;
  }
  if (a[code] > b[code]) {
    return -1;
  }
  return 0;
};

const sortDefaultAsc = (a: any, b: any): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const descDefaultSort = (a: any, b: any): number => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

const ascSortFromCode = (a: any, b: any): number => {
  return ascSort(a, b, "code");
};

const ascSortFromId = (a: any, b: any): number => {
  return ascSort(a, b, "id");
};

const descSortFromId = (a: any, b: any): number => {
  return descSort(a, b, "id");
};

const sortAscFromValue = (a: any, b: any): number => {
  return ascSort(a, b, "value");
};

const sortAscFromName = (a: any, b: any): number => {
  return ascSort(a, b, "name");
};

const ArrayUtils = {
  ascSortFromCode,
  ascSortFromId,
  descSortFromId,
  sortAscFromValue,
  sortDefaultAsc,
  sortAscFromName,
  ascSort,
  descSort,
  ascSortLocalCompare,
  descDefaultSort,
};

export default ArrayUtils;
