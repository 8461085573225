import axios from "axios";
import TokenService from "./token.service";
import ActionLogService from "./actionlog.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.request.use(
  (request: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      request.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
    }
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response: any) {
    response.reponsetime = `${
      new Date().getTime() - response.config.meta.requestStartedAt
    } ms`;
    const data = ActionLogService.createApiMessage(
      response.reponsetime || "0ms",
      response.request.responseURL,
      response.status,
      response.config.data || "",
      response.config.method
    );
    ActionLogService.sendActionLog(data);
    
    return response;
  },
  function (error: any) {
    const originalRequest = error.config;
    if (
      error.response !== undefined &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/auth/token") &&
      !originalRequest.url.includes("/refreshtoken")
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = TokenService.getLocalRefreshToken();
      return new Promise(function (resolve, reject) {
        instance
          .post("/auth/refreshtoken", { refresh_token: refreshToken })
          .then(({ data }) => {
            TokenService.setLocalToken(data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.token;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            TokenService.removeToken();
            window.location.replace("/login");
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
