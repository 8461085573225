import HandleClick from "middleware/handle-click";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { ReactElement, useEffect, useRef } from "react";
import OverlaBookmark from "./OverlayBookmark";
import { Toast } from "primereact/toast";
import useBookmarkStore from "./bookmarkStore";
import PageBookmark from "interfaces/pageBookmark";
import PageService from "services/page.service";
import useHashStore from "pages/painel/report/store/HashStore";
import ToastUtils from "utils/toastUtils";

const PageBookmarkCmp = (): ReactElement => {
  const opBookmark = useRef<OverlayPanel>(null);
  const toastRef = useRef<Toast>(null);
  const setBookmark = useBookmarkStore((state: any) => state.setBookmark);
  const bookmark = useBookmarkStore((state: any) => state.bookmark);

  useHashStore.subscribe(
    (state: any) => state.hash,
    (hash, previousHash) => {
      const link =
        `${window.location.pathname}${window.location.search}`.replaceAll(
          "&",
          "%26"
        );
      setBookmark({
        title: "",
        accountId: 0,
        link: "",
        pageId: 0,
        categoryId: 0,
        geography: "",
        description: "",
      });
    }
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Button
        icon={`pi ${bookmark?.id ? "pi-bookmark-fill" : "pi-bookmark"}`}
        className="p-button-rounded p-button-text p-button-secondary"
        tooltip={`${bookmark?.id ? "Editar" : "Salvar"} página`}
        tooltipOptions={{ position: "bottom" }}
        aria-label="Submit"
        style={{
          width: "40px",
          height: "40px",
          padding: "0",
          borderRadius: "0",
          borderRight: "1px solid #dadada",
        }}
        onClick={(e) => {
          if (!window.location.search) {
            toastRef.current?.show({
              ...ToastUtils.defaultErrorOptions,
              ...{detail: "Não é possível salvar a visualização padrão"},
            });
            return;
          }
          HandleClick.logClickEvents("reports_bookmark", () =>
            opBookmark.current?.toggle(e)
          );
        }}
      />
      <OverlayPanel ref={opBookmark} style={{ width: "500px" }}>
        <OverlaBookmark ovRef={opBookmark} toastRef={toastRef} />
      </OverlayPanel>
    </>
  );
};

export default PageBookmarkCmp;
