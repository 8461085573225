import DataUtils from "utils/DataUtils";
import TokenService from "./token.service";
import { v4 as uuidv4 } from "uuid";
import externalApi from "./external-api";

const appid =
  process.env.REACT_APP_ENV === "development" ? "dev_plataforma" : "plataforma";

const extrairHashes = (hashName: string, search: string): string => {
  if (!search.includes(hashName)) {
    return "";
  }
  const hash = search.split(hashName)[1].replace("=", "");
  if (hash.includes("&")) {
    return hash.split("&")[0];
  }
  return hash;
};

const createSession = (): string => {
  const uuid = uuidv4();
  sessionStorage.setItem(
    "sid",
    JSON.stringify({ val: uuid, date: new Date() })
  );
  return uuid;
};

const getSession = (): string => {
  // remover sessão as 00:00
  const uuid = JSON.parse(sessionStorage.getItem("sid") || "{}");
  if (Object.keys(uuid).length > 0) {
    const date = new Date();
    const diffDate: number = DataUtils.diffInHours(date, new Date(uuid.date));
    if (diffDate < 24) {
      return uuid.val;
    }
  }
  return createSession();
};

const sharedInfos = (): any => {
  return {
    account_id: TokenService.getUserId(),
    filter: extrairHashes("hash_filter", window.location.search),
    params: extrairHashes("hash_page_params", window.location.search),
    route: window.location.pathname,
    sessionId: getSession(),
    userAgent: navigator.userAgent,
    language: navigator.language,
    os: navigator.userAgentData || {},
  };
};

const getDate = (): string => {
  const date = new Date();
  date.setHours(date.getHours() - 3);
  return date.toISOString().replace("Z", "000");
};

const createApiMessage = (
  responseTime: string,
  apiURI: string,
  statusCode: number,
  body: string,
  method: string
): any => {
  return {
    created_at: getDate(),
    applicationId: appid,
    payload: {
      ...{
        type: "api",
        click_name: "api",
        api_uri: apiURI,
        response_time: responseTime,
        status_code: statusCode,
        body: body,
        method: method,
        window_size: `${window.screen.width}x${window.screen.height}`
      },
      ...sharedInfos(),
    },
  };
};

const createClickMessage = (clickName: string): any => {
  return {
    created_at: getDate(),
    applicationId: appid,
    payload: {
      ...{
        type: "click",
        click_name: clickName,
        window_size: `${window.screen.width}x${window.screen.height}`
      },
      ...sharedInfos(),
    },
  };
};

const createGenericMessage = (message: string): any => {
  return {
    created_at: getDate(),
    applicationId: appid,
    payload: {
      ...{
        type: "system",
        click_name: "system",
        message: message,
        window_size: `${window.screen.width}x${window.screen.height}`
      },
      ...sharedInfos(),
    },
  };
};

const sendActionLog = (data: any): any => {
  if (process.env.REACT_APP_ENV === "local") {
    return;
  }
  externalApi
    .post(
      "https://us-central1-v360-dado-bruto.cloudfunctions.net/pub-sub-action-log",
      data,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
    .then((response: any) => {
      // npv
    })
    .catch((error) => {
      // npv
    });
};

// To monday, create a component to intermediate the onclick events to all buttons and onther user interactions

const ActionLogService = {
  createClickMessage,
  createApiMessage,
  createGenericMessage,
  sendActionLog,
};

export default ActionLogService;
