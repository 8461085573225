const getUrlParamFromString = (
  urlString: string,
  paramName: string
): string => {
  const value = urlString
    .split("&")
    .filter((item) => item.includes(paramName))[0];
  if (value) {
    return decodeURI(value.split("=")[1]);
  }
  return "";
};

const UrlUtils = { getUrlParamFromString };

export default UrlUtils;
